import React from "react";

function OurFeatures(){
    return(
        <React.Fragment>
           <section className="py-5 bg-white">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12">
                            <h3 className="fw-bold">
                                Our Features
                            </h3>
                        </div>
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                        <div className="col">
                            <div className="card border-0 h-100">
                                <div className="card-body">
                                    <img src="assets/skillbary-theme/static-assets/our-features/recorded-lecture.svg" className="card-img-top w-25" alt="..."/>

                                    <h5 className="card-title my-4">Recorded Lectures</h5>
                                    <p className="card-text">Learn anytime anywhere with self 
                                        paced videos by subject matter 
                                        experts to clear your fundamental 
                                        concepts.
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col">
                            <div className="card border-0 h-100">
                                <div className="card-body">
                                    <img src="assets/skillbary-theme/static-assets/our-features/case-studies.svg" className="card-img-top w-25" alt="..."/>

                                    <h5 className="card-title my-4">Case Studies</h5>
                                    <p className="card-text">
                                        Gain practical knowledge with case studies and assignments to polish your skills.
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col">
                            <div className="card border-0 h-100">
                                <div className="card-body">
                                    <img src="assets/skillbary-theme/static-assets/our-features/internship.svg" className="card-img-top w-25" alt="..."/>

                                    <h5 className="card-title my-4">Internship Support</h5>
                                    <p className="card-text">
                                        Gain corporate experience
                                        and increase your odds of getting a secure job.
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col">
                            <div className="card border-0 h-100">
                                <div className="card-body">
                                    <img src="assets/skillbary-theme/static-assets/our-features/masterclass.svg" className="card-img-top w-25" alt="..." />

                                    <h5 className="card-title my-4">Masterclass</h5>
                                    <p className="card-text">
                                        Gain extensive knowledge from industry experts and learn from their experiences.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    );
}

export default OurFeatures;