import React from "react";

import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

const alumnisettings = {
  lazyload: true,
  loop: true,
  items: 6,
  gutter: 20,
  slideBy: 1,
  controls: false,
  pages: false,
  nav: false,
  mouseDrag: true,
  autoplay: true,
  autoHeight: true,
  autoplayButtonOutput: false,
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1440: {
      items: 6,
    },
  },
};

const alumniworksettings = {
  lazyload: true,
  loop: true,
  items: 6,
  gutter: 20,
  slideBy: 1,
  controls: false,
  pages: false,
  nav: false,
  mouseDrag: true,
  autoplay: true,
  autoHeight: true,
  autoplayButtonOutput: false,
  responsive: {
    0: {
      items: 1,
    },
    389: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1440: {
      items: 6,
    },
  },
};

function OurAlumni() {
  return (
    <React.Fragment>
      <section className="py-5 bg-light">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12">
              <h3 className="fw-bold">Our Alumni</h3>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="row container g-4">
                <div className="alumni-slider">
                  <TinySlider settings={alumnisettings}>
                    <div className="col py-2">
                      <div className="card h-100 rounded-4 text-center">
                        <div className="card-body">
                          <img
                            src="assets/skillbary-theme/static-assets/alumnis/rajat-chauhan.png"
                            className="img-fluid rounded-circle"
                            alt="..."
                          />
                          <p className="card-text text-gray py-4">
                            Rajat Chauhan
                          </p>
                        </div>
                        <div className="card-footer bg-transparent border-0">
                          <img
                            src="assets/skillbary-theme/static-assets/alumni-work/accenture.svg"
                            className="card-img-top w-50 pb-4"
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col py-2">
                      <div className="card h-100 rounded-4 text-center">
                        <div className="card-body">
                          <img
                            src="assets/skillbary-theme/static-assets/alumnis/priya-gehlot.png"
                            className="img-fluid rounded-circle"
                            alt="..."
                          />
                          <p className="card-text text-gray py-4">
                            Priya Gehlot
                          </p>
                        </div>
                        <div className="card-footer bg-transparent border-0">
                          <img
                            src="assets/skillbary-theme/static-assets/alumni-work/amazon.svg"
                            className="card-img-top w-50 pb-4"
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col py-2">
                      <div className="card h-100 rounded-4 text-center">
                        <div className="card-body">
                          <img
                            src="assets/skillbary-theme/static-assets/alumnis/rajat-chauhan.png"
                            className="img-fluid rounded-circle"
                            alt="..."
                          />
                          <p className="card-text text-gray py-4">
                            Rajat Chauhan
                          </p>
                        </div>
                        <div className="card-footer bg-transparent border-0">
                          <img
                            src="assets/skillbary-theme/static-assets/alumni-work/accenture.svg"
                            className="card-img-top w-50 pb-4"
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col py-2">
                      <div className="card h-100 rounded-4 text-center">
                        <div className="card-body">
                          <img
                            src="assets/skillbary-theme/static-assets/alumnis/priya-gehlot.png"
                            className="img-fluid rounded-circle"
                            alt="..."
                          />
                          <p className="card-text text-gray py-4">
                            Priya Gehlot
                          </p>
                        </div>
                        <div className="card-footer bg-transparent border-0">
                          <img
                            src="assets/skillbary-theme/static-assets/alumni-work/amazon.svg"
                            className="card-img-top w-50 pb-4"
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col py-2">
                      <div className="card h-100 rounded-4 text-center">
                        <div className="card-body">
                          <img
                            src="assets/skillbary-theme/static-assets/alumnis/rajat-chauhan.png"
                            className="img-fluid rounded-circle"
                            alt="..."
                          />
                          <p className="card-text text-gray py-4">
                            Rajat Chauhan
                          </p>
                        </div>
                        <div className="card-footer bg-transparent border-0">
                          <img
                            src="assets/skillbary-theme/static-assets/alumni-work/accenture.svg"
                            className="card-img-top w-50 pb-4"
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col py-2">
                      <div className="card h-100 rounded-4 text-center">
                        <div className="card-body">
                          <img
                            src="assets/skillbary-theme/static-assets/alumnis/priya-gehlot.png"
                            className="img-fluid rounded-circle"
                            alt="..."
                          />
                          <p className="card-text text-gray py-4">
                            Priya Gehlot
                          </p>
                        </div>
                        <div className="card-footer bg-transparent border-0">
                          <img
                            src="assets/skillbary-theme/static-assets/alumni-work/amazon.svg"
                            className="card-img-top w-50 pb-4"
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col py-2">
                      <div className="card h-100 rounded-4 text-center">
                        <div className="card-body">
                          <img
                            src="assets/skillbary-theme/static-assets/alumnis/rajat-chauhan.png"
                            className="img-fluid rounded-circle"
                            alt="..."
                          />
                          <p className="card-text text-gray py-4">
                            Rajat Chauhan
                          </p>
                        </div>
                        <div className="card-footer bg-transparent border-0">
                          <img
                            src="assets/skillbary-theme/static-assets/alumni-work/accenture.svg"
                            className="card-img-top w-50 pb-4"
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col py-2">
                      <div className="card h-100 rounded-4 text-center">
                        <div className="card-body">
                          <img
                            src="assets/skillbary-theme/static-assets/alumnis/priya-gehlot.png"
                            className="img-fluid rounded-circle"
                            alt="..."
                          />
                          <p className="card-text text-gray py-4">
                            Priya Gehlot
                          </p>
                        </div>
                        <div className="card-footer bg-transparent border-0">
                          <img
                            src="assets/skillbary-theme/static-assets/alumni-work/amazon.svg"
                            className="card-img-top w-50 pb-4"
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>
                  </TinySlider>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-5 ">
            <div className="col-12">
              <h3 className="fw-bold">Where Our Alumnis Work</h3>
            </div>
          </div>
          <div className="row text-center ">
            <TinySlider settings={alumniworksettings}>
              <div className="col py-2">
                <img
                  src="assets/skillbary-theme/static-assets/alumni-work/accenture.svg"
                  className="card-img-top w-50 "
                  alt="..."
                />
              </div>

              <div className="col py-2">
                <img
                  src="assets/skillbary-theme/static-assets/alumni-work/amazon.svg"
                  className="card-img-top w-50 "
                  alt="..."
                />
              </div>

              <div className="col py-2">
                <img
                  src="assets/skillbary-theme/static-assets/alumni-work/accenture.svg"
                  className="card-img-top w-50 "
                  alt="..."
                />
              </div>

              <div className="col py-2">
                <img
                  src="assets/skillbary-theme/static-assets/alumni-work/amazon.svg"
                  className="card-img-top w-50 "
                  alt="..."
                />
              </div>
              <div className="col py-2">
                <img
                  src="assets/skillbary-theme/static-assets/alumni-work/accenture.svg"
                  className="card-img-top w-50 "
                  alt="..."
                />
              </div>

              <div className="col py-2">
                <img
                  src="assets/skillbary-theme/static-assets/alumni-work/amazon.svg"
                  className="card-img-top w-50 "
                  alt="..."
                />
              </div>
              <div className="col py-2">
                <img
                  src="assets/skillbary-theme/static-assets/alumni-work/accenture.svg"
                  className="card-img-top w-50 "
                  alt="..."
                />
              </div>

              <div className="col py-2">
                <img
                  src="assets/skillbary-theme/static-assets/alumni-work/amazon.svg"
                  className="card-img-top w-50"
                  alt="..."
                />
              </div>
            </TinySlider>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default OurAlumni;
