import React from 'react'
import Header from '../Header'
import Footer from '../Footer'

export default function CareersIndex() {
  return (
    <>
      <main className="flex-shrink-0">
        <Header/>
        <h1 className='text-center mt-5'>
            Career Page Coming Soon...
        </h1>

      </main>
      <Footer/>
    </>
  )
}
