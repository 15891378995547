import React, { useState, useContext, useEffect } from "react";
import { multiStepContext } from "../StepContext";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import htmlParse from "html-react-parser";

import {
  apiEndPoint,
  splitGSettingString,
  CryptoJSAesEncrypt,
  CryptoJSAesDecrypt,
  toastUniqueId,
  limitDesString,
} from "../Helpers";

import Header from "../Header";
import Footer from "../Footer";

export default function MyCourses() {
  const generalSetting = localStorage.getItem("generalSetting");
  const loggedInUser = localStorage.getItem("is_loagged_in");

  const [loadMyCourses, setLoadMyCourses] = useState(null);
  const { loginData, finalData } = useContext(multiStepContext);

  const [loggedUserData, setLoggedUserData] = useState(null);
  const [authorization, setAuthorization] = useState(null);

  console.log("finalData from UserAcount.js", finalData);

  useEffect(() => {
    if (finalData !== null) {
      setLoggedUserData(finalData.verified_user_data.user_data);
      setAuthorization(finalData.verified_user_data.Authorization);
    }

    const getMyCoursesData = async (event) => {
      if (generalSetting) {
        const [api_user_name, api_token_name] = splitGSettingString(
          generalSetting,
          27
        );

        const token = CryptoJSAesEncrypt(api_user_name, api_token_name);

        console.log(
          "finalData from Authorization",
          finalData.verified_user_data.Authorization
        );

        const bodyParameters = {
          page: 1,
          per_page: 12,
          user_id: finalData.verified_user_data.user_data.user_id,
          token: token,
          activity_by: "web",
        };

        const res = await axios({
          url: apiEndPoint() + "/mobile_app/courses/get_enrolled_courses",
          data: bodyParameters,
          method: "post",
          headers: {
            Authorization: `Bearer ${finalData.verified_user_data.Authorization}`,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
          .then(function (res) {
            console.log(res.data);
            if (res.data.status === 200) {
              console.log(
                "get_enrolled_courses res res.data.token",
                res.data.token
              );

              const decryptData = CryptoJSAesDecrypt(
                res.data.token,
                res.data.data
              );

              console.log("get_enrolled_courses res decryptData", decryptData);

              if (decryptData) {
                const respData = JSON.parse(decryptData);
                console.log("ourCourse respData", respData);

                setLoadMyCourses(respData.list);
              }
            } else if (res.data.status === 401) {
              toast.error(res.data.message, {
                theme: "dark",
                position: "bottom-center",
                toastId: toastUniqueId(),
                onClose: () => {
                  //e.preventDefault();
                  setLoggedUserData(null);
                  setAuthorization(null);
                  navigate("/logout");
                },
              });
            }
          })
          .catch(function (error) {
            //console.log("Error b", error);

            toast.info("Error Found", {
              theme: "dark",
              position: "bottom-center",
              toastId: toastUniqueId(),
              onClose: () => {
                setLoggedUserData(null);
                setAuthorization(null);
                navigate("/logout");
              },
            });
          });
      }
    };
    getMyCoursesData();
  }, []);

  const navigate = useNavigate();
  if (!loggedInUser) {
    return <Navigate replace={true} to="/login" />;
    //navigate("/logout");
  }

  return (
    <>
      <main className="flex-shrink-0">
        <Header />

        {loadMyCourses ? (
          <div>
            <section className="pt-5" style={{ marginTop: "5rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col mb-5 mb-lg-0 text-left">
                    <div className="d-flex">
                      <div>
                        <h3 className="lh-2">My Courses</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="bg-white">
              <div className="container">
                <div className="row g-0">
                  <div className="col">
                    <div className="card border-0">
                      <div className="card-body px-0">
                        <div className="list-group">
                          {loadMyCourses.map((course, index) => (
                            <div
                              className="list-group-item align-middle border-1 rounded-4 p-3"
                              key={index}
                            >
                              <div className="d-block d-md-flex w-100 ">
                                <div className="d-flex flex-grow-0">
                                  <img
                                    src={course.full_featured_image}
                                    className="rounded-4 img-fluid img-thumbnail "
                                    style={{ width: "160px" }}
                                    alt="Skillbary"
                                  />
                                  <div className="ms-3">
                                    <h6
                                      className="mb-1 text-wrap"
                                      style={{
                                        width: "100%",
                                        fontSize: "auto",
                                      }}
                                    >
                                      {course.title}
                                    </h6>
                                    <p className="mb-1 small">
                                      {course.course_educator_list.map(
                                        (educator, eduindex) => (
                                          <span
                                            className="badge bg-light text-light me-1"
                                            key={eduindex}
                                          >
                                            {educator.educator_name}
                                          </span>
                                        )
                                      )}
                                    </p>
                                    <div
                                      className="small text-wrap text-muted  text-wrap"
                                      style={{ width: "50%" }}
                                    >
                                      {limitDesString(
                                        course.web_description,
                                        150
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-block flex-grow-1  ms-3">
                                    <small className="text-primary">
                                      {course.progress_percentage}% Completed
                                    </small>
                                    <div
                                      className="progress w-50 rounded-pill"
                                      role="progressbar"
                                      aria-label="Example with label"
                                      aria-valuenow={course.progress_percentage}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    >
                                      <div
                                        className="progress-bar"
                                        style={{
                                          width: `${course.progress_percentage}%`,
                                        }}
                                      >
                                        {course.progress_percentage}%
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-block text-center my-3 my-md-0">
                                    <Link
                                      className="btn btn-primary rounded-pill px-4 fw-bold float-md-end"
                                      to={"/learn/" + course.course_id}
                                    >
                                      {" "}
                                      Continue
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="card-footer bg-transparent border-0">
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div>
            <h2>Course Loading...</h2>
          </div>
        )}
      </main>
      <Footer />
    </>
  );
}
