import React from "react";

export default function SectionResources(props) {
  let resourceTopicData = props.resourceTopicData;
  let resourceQuizData = props.resourceQuizData;
  let resourceAssignmentData = props.resourceAssignmentData;

  let resourceCaseMaster = props.resourceCaseMaster;

  console.log("resourceQuizData here", resourceQuizData);
  
  return (
    <>
      {resourceTopicData ? (
        <ul className="list-group mb-3">
          {resourceTopicData.map((topic, topic_index) => (
            
            <li
              className="list-group-item list-group-item-action rounded rounded-4"
              key={topic_index}
            >
              <div className="hstack gap-3">
                <div className="text-primary">
                  <span>
                    {topic.resource_type === "video" ? (
                      <i className="bi bi-file-play-fill"></i>
                    ) : topic.resource_type === "text" ? (
                      <i className="bi bi-file-text-fill"></i>
                    ) : topic.resource_type === "external_url" ? (
                      <i className="bi bi-box-arrow-up-right"></i>
                    ) : (
                      <i className="bi bi-file-earmark-fill"></i>
                    )}
                  </span>
                </div>
                <div className="text-primary">
                  <h6 className="mb-1">{topic.name}</h6>
                </div>
                <div className="ms-auto text-primary">
                  <a
                    className="btn btn-sm btn-primary rounded-pill px-2 fw-bold"
                    href={"/courses/"+topic.course_id}
                    is_free={topic.free_resource}
                  >
                    {topic.free_resource === 1 ? (
                      <i className="bi bi-play-fill" />
                    ) : (
                      <i className="bi bi-lock" />
                    )}
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : null}
      {resourceQuizData ? (
        <ul className="list-group mb-3">
          {resourceQuizData.map((quiz, quiz_index) => (
            <li
              className="list-group-item list-group-item-action rounded rounded-4"
              key={quiz_index}
            >
              <div className="hstack gap-3">
                <div className="text-primary">
                  <span>
                    {quiz.resource_type === "quiz" ? (
                      <i className="bi bi-patch-question-fill"></i>
                    ) : (
                      <i className="bi bi-file-earmark-fill"></i>
                    )}
                  </span>
                </div>
                <div className="text-primary">
                  <h6 className="mb-1">{quiz.name}</h6>
                </div>
                <div className="ms-auto text-primary">
                  <a
                    className="btn btn-sm btn-primary rounded-pill px-2 fw-bold"
                    href={"/courses/"+quiz.course_id}
                    is_free={quiz.free_resource}
                  >
                    {quiz.free_resource === "1" ? (
                      <i className="bi bi-play-fill" />
                    ) : (
                      <i className="bi bi-lock" />
                    )}
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : null}
      {resourceAssignmentData ? (
        <ul className="list-group mb-3">
          {resourceAssignmentData.map((assignment, assignment_index) => (
            <li
              className="list-group-item list-group-item-action rounded rounded-4"
              key={assignment_index}
            >
              <div className="hstack gap-3">
                <div className="text-primary">
                  <span>
                    {assignment.resource_type === "assignment" ? (
                      <i className="bi bi-journal-bookmark-fill"></i>
                    ) : (
                      <i className="bi bi-file-earmark-fill"></i>
                    )}
                  </span>
                </div>
                <div className="text-primary">
                  <h6 className="mb-1">{assignment.name}</h6>
                </div>
                <div className="ms-auto text-primary">
                  <a
                    className="btn btn-sm btn-primary rounded-pill px-2 fw-bold"
                    href={"/courses/"+assignment.course_id}
                    is_free={assignment.free_resource}
                  >
                    {assignment.free_resource === "1" ? (
                      <i className="bi bi-play-fill" />
                    ) : (
                      <i className="bi bi-lock" />
                    )}
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : null}
      {resourceCaseMaster &&
      resourceCaseMaster.section_type === "master_class" ? (
        <ul className="list-group mb-3">
          {resourceCaseMaster.master_class.map((masterClass, master_index) => (
            <li
              className="list-group-item list-group-item-action rounded rounded-4"
              key={master_index}
            >
              <div className="hstack gap-3">
                <div className="text-primary">
                  <span>
                    {masterClass.resource_type === "video" ? (
                      <i className="bi bi-file-play-fill"></i>
                    ) : masterClass.resource_type === "text" ? (
                      <i className="bi bi-file-text-fill"></i>
                    ) : masterClass.resource_type === "external_url" ? (
                      <i className="bi bi-box-arrow-up-right"></i>
                    ) : masterClass.resource_type === "assignment" ? (
                      <i className="bi bi-journal-bookmark-fill"></i>
                    ) : (
                      <i className="bi bi-file-earmark-fill"></i>
                    )}
                  </span>
                </div>
                <div className="text-primary">
                  <h6 className="mb-1">{masterClass.name}</h6>
                </div>
                <div className="ms-auto text-primary">
                  <a
                    className="btn btn-sm btn-primary rounded-pill px-2 fw-bold"
                    href={"/courses/"+masterClass.course_id}
                    is_free={masterClass.free_resource}
                  >
                    {masterClass.free_resource === "1" ? (
                      <i className="bi bi-play-fill" />
                    ) : (
                      <i className="bi bi-lock" />
                    )}
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : null}

      {resourceCaseMaster &&
      resourceCaseMaster.section_type === "case_studies" ? (
        <ul className="list-group mb-3">
          {resourceCaseMaster.case_studies.map(
            (caseStudies, case_studies_index) => (
              <li
                className="list-group-item list-group-item-action rounded rounded-4"
                key={case_studies_index}
              >
                <div className="hstack gap-3">
                  <div className="text-primary">
                    <span>
                      {caseStudies.resource_type === "video" ? (
                        <i className="bi bi-file-play-fill"></i>
                      ) : caseStudies.resource_type === "text" ? (
                        <i className="bi bi-file-text-fill"></i>
                      ) : caseStudies.resource_type === "external_url" ? (
                        <i className="bi bi-box-arrow-up-right"></i>
                      ) : caseStudies.resource_type === "assignment" ? (
                        <i className="bi bi-journal-bookmark-fill"></i>
                      ) : (
                        <i className="bi bi-file-earmark-fill"></i>
                      )}
                    </span>
                  </div>
                  <div className="text-primary">
                    <h6 className="mb-1">{caseStudies.name}</h6>
                  </div>
                  <div className="ms-auto text-primary">
                    <a
                      className="btn btn-sm btn-primary rounded-pill px-2 fw-bold"
                      href={"/courses/"+caseStudies.course_id}
                      is_free={caseStudies.free_resource}
                    >
                      {caseStudies.free_resource === "1" ? (
                        <i className="bi bi-play-fill" />
                      ) : (
                        <i className="bi bi-lock" />
                      )}
                    </a>
                  </div>
                </div>
              </li>
            )
          )}
        </ul>
      ) : null}
    </>
  );
}
