import React from "react";
import { Link } from "react-router-dom";
//import htmlParse from "html-react-parser";
import {
  limitDesString,
  limitAppDesString,
  addSpaceToString,
} from "../Helpers";
import htmlParse from "html-react-parser";
export default function CourseList(props) {
  console.log("courseData here", props.courseData);
  let courseData = props.courseData;

  return (
    <>
      {courseData ? (
        <section className="py-5" style={{ marginTop: "5rem" }}>
          <div className="container">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
              {courseData.map((course, index) => (
                <div className="col p-2 h-100" key={index}>
                  <div className="card h-100 shadow-custom-card rounded-4 border-0">
                    <img
                      style={{ borderRadius: "3rem" }}
                      src={course.full_featured_image}
                      className="img-fluid card-img-top p-4 image-cropped-transform"
                      alt="..."
                    />
                    <div className="card-body px-4 py-0">
                      <h5 className="card-subtitle fs-6">
                        {course.title.length > 35
                          ? course.title
                          : htmlParse(
                              "<span>" +
                                course.title +
                                "</span>" +
                                '<span class="visually-hiddenx text-white">' +
                                addSpaceToString(course.title, 39) +
                                "</span>"
                            )}
                      </h5>
                      <p className="card-text">
                        {course.course_educator_list.map(
                          (educator, eduindex) => (
                            <span
                              className="me-1"
                              style={{ color: "#1D1B19" }}
                              key={eduindex}
                            >
                              {educator.educator_name}
                            </span>
                          )
                        )}
                      </p>
                    </div>
                    <div className="card-footer bg-transparent border-0">
                      <div className="hstack gap-3 mb-3">
                        <div style={{ color: "#8F8F8F" }}>
                          <span className="me-1">
                            <img
                              src="assets/skillbary-theme/static-assets/courses/star-rating.svg"
                              style={{ width: "16px" }}
                              alt="..."
                            />
                          </span>
                          <span>4.7</span>
                        </div>
                        <div style={{ color: "#8F8F8F" }}>
                          <span>
                            <i className="bi bi-person-circle"></i>{" "}
                            {Math.floor(Math.random() * 10 + 1)}k
                          </span>
                        </div>
                        <div className="d-none">
                          <Link
                            className="btn btn-sm btn-primary rounded-pill px-2 fw-bold"
                            to={"/courses/" + course.course_id}
                          >
                            Explore
                            <i className="bi bi-arrow-right-short"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : (
        <div>
          <h2>Course Loading...</h2>
        </div>
      )}
    </>
  );
}
