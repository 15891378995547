import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { multiStepContext } from "../StepContext";

import {
  apiEndPoint,
  splitGSettingString,
  CryptoJSAesEncrypt,
  CryptoJSAesDecrypt,
  toastUniqueId,
} from "../Helpers";

export default function NewMobileOtp(props) {
  const generalSetting = localStorage.getItem("generalSetting");

  const {
    currentStep,
    setStep,
    loginData,
    setLoginData,
    finalData,
    setFinalData,
  } = useContext(multiStepContext);

  const navigate = useNavigate();
  const initialCounter = 10;
  const [resendCounter, setresendCounter] = useState(initialCounter);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  const initialText = "Continue";
  const [buttonText, setButtonText] = useState(initialText);

  const initialResentText = "Resend in " + resendCounter + " Sec";
  const [resentButtonText, setresentButtonText] = useState(initialResentText);

  useEffect(() => {
    const timer =
      resendCounter > 0 &&
      setInterval(() => setresendCounter(resendCounter - 1), 1000);

    console.log("resendCounter here", resendCounter);

    if (timer === false) {
      setIsResetDisabled(false);
      setresentButtonText("Resend OTP");
      //resendCounter = "Resend OTP";
    } else {
      setresentButtonText(initialResentText);
    }

    return () => clearInterval(timer);
  }, [resendCounter, initialResentText]);

  const handleSubmitOtpSubmit = (e) => {
    e.preventDefault();

    setIsResetDisabled(true);
    setButtonText("Loading...");

    apiSubmitOtpLogin();
  };

  const apiSubmitOtpLogin = async () => {
    if (generalSetting) {
      const [api_user_name, api_token_name] = splitGSettingString(
        generalSetting,
        27
      );

      const token = CryptoJSAesEncrypt(api_user_name, api_token_name);
      console.log("api_token_name token", token);

      setIsLoading(true);
      setIsDisabled(true);

      const formData = new FormData();

      formData.append("dial_code", loginData["dial_code"]);
      formData.append("mobile", loginData["mobile"]);
      formData.append("otp", loginData["otp"]);
      formData.append("activity_by", loginData["activity_by"]);
      formData.append("token", token);
      formData.append("activity_by", "web");

      axios
        .post(apiEndPoint() + "/mobile_app/account/verify_otp", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            // Content-Type: application/json; charset=utf-8
          },
        })
        .then((res) => {
          console.log("verify_otp res", res);

          if (res.data.status === 200) {
            console.log("verify_otp res status", res.data.status);

            console.log("verify_otp res res.data.data", res.data.data);
            console.log("verify_otp res res.data.token", res.data.token);

            const decryptData = CryptoJSAesDecrypt(
              res.data.token,
              res.data.data
            );

            console.log("verify_otp res decryptData", decryptData);

            if (decryptData) {
              const respData = JSON.parse(decryptData);
              console.log("ourCourse respData", respData);

              toast.success(res.data.message, {
                theme: "dark",
                position: "bottom-center",
                toastId: toastUniqueId(),
                onClose: () => {
                  setIsDisabled(false);
                  setIsResetDisabled(false);
                  setLoginData({ ...loginData, is_otp_verified: true });
                  setFinalData({
                    ...finalData,
                    verified_user_data: respData,
                  });
                  //setFinalData({finalData,res.data.data});
                  localStorage.setItem("is_loagged_in", true);
                  localStorage.setItem("mobile", loginData["mobile"]);
                  localStorage.setItem("is_refferal", loginData["is_refferal"]);
                  localStorage.setItem("loginAction", respData.action);
                  localStorage.setItem(
                    "verified_user_data",
                    JSON.stringify({ verified_user_data: respData })
                  );

                  console.log("MobileOtp action click", res.data.data.action);
                  if (respData.action === "register") {
                    console.log("register action", respData.action);
                    setStep(currentStep + 1);
                    setIsDisabled(false);
                    setButtonText(initialText);
                  } else if (respData.action === "login") {
                    console.log("login action", respData.action);
                    navigate(0);
                  }

                  //e.preventDefault();
                  //setStep(currentStep + 1);
                },
              });
            }
          } else if (res.data.message !== "") {
            toast.success(res.data.message, {
              theme: "dark",
              position: "bottom-center",
              toastId: toastUniqueId(),
              onClose: () => {
                //e.preventDefault();
                setIsResetDisabled(false);
                setIsDisabled(false);
              },
            });
          }
        })
        .catch((error) => {
          //console.log("Error a", error);

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("Error b", error.response.data);

            toast.error(error.response.data.message, {
              theme: "dark",
              position: "bottom-center",
              toastId: toastUniqueId(),
              onClose: () => {
                setIsResetDisabled(false);
                setIsDisabled(false);
                loginData["otp"] = null;
                setLoginData({ ...loginData, otp: null });
                //e.preventDefault();
                //setStep(currentStep + 1);
              },
            });

            //console.log("Error c", error.response.status);
            //console.log("Error d", error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log("Error e", error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error f A", error.message);
          }
          //console.log("Error g", error.config);
        });

      setIsLoading(false);

      setButtonText(initialText);
    }
  };

  const apiRequestOtpLogin = () => {
    if (generalSetting) {
      const [api_user_name, api_token_name] = splitGSettingString(
        generalSetting,
        27
      );

      const token = CryptoJSAesEncrypt(api_user_name, api_token_name);
      console.log("api_token_name token", token);

      setIsLoading(true);
      setIsResetDisabled(true);

      const formData = new FormData();

      formData.append("dial_code", loginData["dial_code"]);
      formData.append("mobile", loginData["mobile"]);
      formData.append("request_type", loginData["request_type"]);
      formData.append("token", token);
      formData.append("activity_by", "web");

      axios
        .post(apiEndPoint() + "/mobile_app/account/login", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            // Content-Type: application/json; charset=utf-8
          },
        })
        .then((res) => {
          console.log("then clo", res);

          if (res.data.status === 200) {
            toast.success(res.data.message, {
              theme: "dark",
              position: "bottom-center",
              toastId: toastUniqueId(),
              onClose: () => {
                //e.preventDefault();
                //setStep(currentStep + 1);
                //setIsDisabled(false);
                //setButtonText(initialText);

                setresendCounter(initialCounter);
                setresentButtonText(initialResentText);
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err, {
            theme: "dark",
            position: "bottom-center",
            toastId: toastUniqueId(),
          });
          setIsDisabled(false);
        });

      setIsLoading(false);
    }
  };

  return (
    <>
      <section>
        <div className="container">
          <div>
            <div className="d-flex">
              <div>
                <h5 className="mb-1">
                  Enter the Code <br />
                  sent to your Phone
                </h5>
                <p className="text-muted">
                  <span>
                    We sent it to{" "}
                    {loginData["dial_code"] + "-" + loginData["mobile"]}
                  </span>
                </p>
              </div>
            </div>
            <div>
              <form onSubmit={handleSubmitOtpSubmit}>
                <div className="hstack hstack-sm gap-3">
                  <input
                    className="form-control-lg me-auto otp"
                    maxLength={4}
                    type="text"
                    placeholder="0123"
                    aria-label="0123"
                    value={loginData["otp"] || ""}
                    onChange={(e) =>
                      setLoginData({ ...loginData, otp: e.target.value })
                    }
                  />

                  
                </div>
                <div>
                  <label className="text-muted">
                    <span>Didn’t recieve OTP?</span>{" "}
                    <span>
                      <button
                        type="button"
                        className="btn btn-link"
                        disabled={isResetDisabled}
                        onClick={() => {
                          apiRequestOtpLogin();
                        }}
                      >
                        {resentButtonText}
                      </button>
                    </span>
                  </label>
                  <p>
                    <button
                      className="backbtn btn btn-link"
                      onClick={() => {
                        setStep(currentStep - 1);
                      }}
                    >
                      <i className="bi bi-pencil-square" /> Edit Number
                    </button>
                  </p>
                </div>
                <div className="d-grid gap-2 col-12 mx-auto mt-4">
                  <button
                    className="btn btn-primary rounded-pill "
                    disabled={isDisabled}
                  >
                    {buttonText}
                  </button>
                  
                  <button
                    type="button"
                    className="btn btn-light rounded-pill"
                    onClick={props.close}
                    style={{ backgroundImage: "none", boxShadow: "none" }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
