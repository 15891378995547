import React from "react";
import SectionResources from "./SectionResources";

export default function SectionAccordion(props) {
  console.log("accSecData here", props.accSecData);
  let accSecData = props.accSecData;

  return (
    <>
      {accSecData ? (
        <div className="accordion" id="curriculum-accordion-section">
          {accSecData.map((Section, sec_index) => (
            <div className="accordion-section" key={sec_index}>
              <h2 className="accordion-header" id={"headingOneSec" + sec_index}>
                <button
                  className={
                    "accordion-button " + (sec_index === 0 ? "" : "collapsed")
                  }
                  style={{
                    color: "#fff",
                    background: "transparent",
                  }}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#collapseOneSec" + sec_index}
                  aria-expanded={sec_index === 0 ? "true" : "false"}
                  aria-controls={"collapseOneSec" + sec_index}
                >
                  {Section.module_name}
                </button>
              </h2>
              <div
                id={"collapseOneSec" + sec_index}
                className={
                  "accordion-collapse collapse " +
                  (sec_index === 0 ? "show" : "")
                }
                aria-labelledby={"headingOneSec" + sec_index}
                data-bs-parent="#curriculum-accordion-section"
              >
                <div className="accordion-body">
                  {Section.section_type === "curriculum" ? 
                  <SectionResources
                      resourceTopicData={Section.topic_data}
                      resourceQuizData={Section.quizzes_data}
                      resourceAssignmentData={Section.assignments_data}
                    /> : 
                    "non curriculumn"}
                  
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <h6>Curriculum Sections Under Development...</h6>
        </div>
      )}
    </>
  );
}
