import React, { useState, useEffect, useContext } from "react";
import { multiStepContext } from "../StepContext";

export default function UserName() {
  const { currentStep, setStep, loginData, setLoginData } = useContext(multiStepContext);

  console.log('UserName called');

  return (
    <>
      <section className="py-5 bg-white" style={{ marginTop: "5rem" }}>
        <div className="container">
          <div className="px-4 py-5 my-5">
            <div className="d-flex">
              <div
                className="backbtn"
                onClick={() => {
                  setStep(currentStep);
                }}
              >
                <i className="bi bi-arrow-left fs-4 fw-bold" />
              </div>
              <div className="ms-3">
                <h5 className="mb-1">Enter your Name</h5>
                <p className="text-muted">
                  <span>We want to make it more personal for you</span>
                </p>
              </div>
            </div>
            <div>

              <form>
                <div className="hstack hstack-sm gap-3">
                  <input
                    className="form-control-lg me-auto name"
                    maxLength={30}
                    type="text"
                    placeholder="Name Here"
                    aria-label="Name Here"

                    value={loginData['name'] || ""}
                    onChange={(e) => setLoginData({...loginData,"name":e.target.value})}
                  />
                  <button
                    className="btn btn-primary rounded-pill btn-lg px-4 gap-3"
                    onClick={() => setStep(currentStep + 1)}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
