import React, { useContext, useState } from "react";
import { multiStepContext } from "../StepContext";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Guest from "./Guest";
import NewMobileNumber from "./NewMobileNumber";
import NewMobileOtp from "./NewMobileOtp";
import UserName from "./UserName";

import UserAvatar from "./UserAvatar";
import UserQualification from "./UserQualification";
import UserReferral from "./UserReferral";

function Form(props) {
  const [isDisabled, setIsDisabled] = useState(false);
  const initialText = "Continue";
  const [buttonText, setButtonText] = useState(initialText);

  const {
    currentStep,
    /*setStep,
    loginData,
    setLoginData,
    finalData,
    setFinalData,*/
  } = useContext(multiStepContext);

  const StepDisplay = (formStepNo) => {
    //console.log("formStepNo data", formStepNo);
    //return <h1>Return StepDisplay</h1>;
    console.log("StepDisplay formStepNo", formStepNo);

    if (formStepNo > 6) {
      formStepNo = 0;
    }
    switch (formStepNo) {
      //case 0:
      //  return <Guest />;

      case 0:
        return <NewMobileNumber show={props.show} close = {props.close} />;

      case 1:
        return <NewMobileOtp />;

      case 2:
        return <UserName />;

      case 3:
        return <UserAvatar />;

      case 4:
        return <UserQualification />;

      case 5:
        return <UserReferral />;

      default:
        return <NewMobileNumber />;
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        contentClassName="rounded rounded-4 h-100"
      >
        <Modal.Body>{StepDisplay(currentStep)}</Modal.Body>
        
      </Modal>
    </>
  );
}

export default Form;
