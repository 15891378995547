import React, { useState, useContext, useEffect } from "react";
import { multiStepContext } from "../StepContext";
import { Link } from "react-router-dom";
import htmlParse from "html-react-parser";
import axios from "axios";
import {
  apiEndPoint,
  splitGSettingString,
  CryptoJSAesEncrypt,
  CryptoJSAesDecrypt,
  addSpaceToString,
} from "../Helpers";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

const settings = {
  items: 3,
  gutter: 0,
  slideBy: "page",
  nav: false,
  loop: false,
  autoplay: false,
  mouseDrag: true,
  autoHeight: true,
  autoplayButtonOutput: false,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1440: {
      items: 3,
    },
  },
};

export function OurCourses() {
  const [loadCourses, setLoadCourses] = useState(null);

  const loggedInUser = localStorage.getItem("is_loagged_in");

  const { loginData, finalData } = useContext(multiStepContext);

  const generalSetting = localStorage.getItem("generalSetting");
  //const { generalSetting, setGetGeneralSetting } = useContext(multiStepContext);

  const [loggedUserData, setLoggedUserData] = useState(null);
  const [authorization, setAuthorization] = useState(null);
  const [action, setAction] = useState();

  var user_id = 0;

  console.log("finalData from UserAcount.js ourCourse", finalData);
  console.log(
    "addSpaceToString here",
    "<span>" +
      addSpaceToString("Digital Marketing Mastery Course", 39) +
      "</span>"
  );

  useEffect(() => {
    if (loggedInUser === 'true' && finalData !== null) {
      setLoggedUserData(finalData.verified_user_data.user_data);
      setAuthorization(finalData.verified_user_data.Authorization);
      setAction(finalData.verified_user_data.action);
    }

    const getCoursesData = async () => {
      if (loggedInUser === 'true' &&  finalData !== null) {
        user_id = (finalData.verified_user_data.action == 'login')?finalData.verified_user_data.user_data.user_id:0;
      } else {
        user_id = 0;
      }

      if (generalSetting) {
        const [api_user_name, api_token_name] = splitGSettingString(
          generalSetting,
          27
        );

        const token = CryptoJSAesEncrypt(api_user_name, api_token_name);
        console.log("api_token_name token", token);

        const bodyParameters = {
          page: 1,
          per_page: 12,
          user_id: user_id,
          token: token,
          activity_by: "web",
        };

        const res = await axios({
          url: apiEndPoint() + "/mobile_app/courses/courses",
          data: bodyParameters,
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.data.status === 200) {
          console.log("ourCourse res.token", res.data.token);

          const decryptData = CryptoJSAesDecrypt(res.data.token, res.data.data);

          if (decryptData) {
            const courseList = JSON.parse(decryptData);
            console.log("ourCourse courseList", courseList);
            setLoadCourses(courseList.list);
          }

          //console.log('varGetGeneralSetting here',varGetGeneralSetting);
        }
      }
    };
    getCoursesData();
  }, []);

  return (
    <>
      {loadCourses ? (
        <div>
          <section className="py-5">
            <div className="container">
              <div className="row mb-4">
                <div className="col">
                  <div className="d-flex justify-content-between w-100">
                    <p className="fw-bold text-start fs-3">Our Courses</p>
                    <p className="fw-bold text-primary text-end d-none">
                      <Link className="btn btn-link fw-bold" to="/courses">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="row container m-0 gx-5">
                    <TinySlider settings={settings}>
                      {loadCourses.map((course, index) => (
                        <div className="col p-2 h-100" key={index}>
                          <div
                            className="card h-100 mb-3 shadow-custom-card border-0"
                            style={{ borderRadius: "1rem" }}
                          >
                            <img
                              style={{ borderRadius: "3rem" }}
                              src={course.full_featured_image}
                              className="img-fluid card-img-top p-4 image-cropped-transform"
                              alt="..."
                            />
                            <div className="card-body px-4 py-0">
                              <h5 className="card-subtitle fs-6">
                                {course.title.length > 35
                                  ? course.title
                                  : htmlParse(
                                      "<span>" +
                                        course.title +
                                        "</span>" +
                                        '<span class="visually-hiddenx text-white">' +
                                        addSpaceToString(course.title, 39) +
                                        "</span>"
                                    )}
                              </h5>
                              <p className="card-text">
                                {course.course_educator_list.map(
                                  (educator, eduindex) => (
                                    <span
                                      className="me-1"
                                      style={{ color: "#1D1B19" }}
                                      key={eduindex}
                                    >
                                      {educator.educator_name}
                                    </span>
                                  )
                                )}
                              </p>
                              <p className="card-text d-none">
                                <small className="text-muted">
                                  {course.app_description}
                                </small>
                              </p>
                            </div>
                            <div className="card-footer bg-transparent border-0">
                              <div className="hstack gap-3 mb-3">
                                <div style={{ color: "#8F8F8F" }}>
                                  <span className="me-1">
                                    <img
                                      src="assets/skillbary-theme/static-assets/courses/star-rating.svg"
                                      style={{ width: "16px" }}
                                      alt="..."
                                    />
                                  </span>
                                  <span>4.7</span>
                                </div>
                                <div style={{ color: "#8F8F8F" }}>
                                  <span>
                                    <i className="bi bi-person-circle"></i>{" "}
                                    {Math.floor(Math.random() * 10 + 1)}k
                                  </span>
                                </div>
                                <div className="d-none">
                                  <Link
                                    className="btn btn-sm btn-primary rounded-pill px-2 fw-bold"
                                    to={"/courses/" + course.course_id}
                                  >
                                    Explore
                                    <i className="bi bi-arrow-right-short"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </TinySlider>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-custom-halfgraywhitex">
            <div className="container">
              <div className="row">
                <div
                  className="col p-2 align-middle text-center"
                  style={{ textAlign: "center" }}
                >
                  <div className="d-grid gap-2 col-2 col-sm-4 mx-auto">
                    <Link
                      className="btn btn-lg btn-primary rounded-pill px-4 fw-bold "
                      to="/courses"
                    >
                      View All
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div>
          <h2>Course Loading...</h2>
        </div>
      )}
    </>
  );
}

export default OurCourses;
