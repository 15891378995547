import React, { useContext,  useState } from "react";
import { Link } from "react-router-dom";
import { multiStepContext } from "../StepContext";

export default function UserAccount() {
  const { loginData, finalData } = useContext(multiStepContext);

  console.log("loginData from UserAcount.js", loginData);
  console.log("finalData from UserAcount.js", finalData);

  //const [action, setAction] = useState(finalData.action);
  //const [authToken, setAuthToken] = useState(finalData.Authorization);
  const [loggedUserData] = useState(finalData.verified_user_data.user_data);
  


  return (
    <>
      <section className="py-5" style={{ marginTop: "5rem" }}>
        <div className="container">
          <div className="row mb-4">
            <div className="col-12 col-lg-3 mb-5 mb-lg-0 text-center text-lg-left">
              <div className="d-md-flex d-block">
                <img
                  className="rounded-circle"
                  style={{ height: 50, width: 50, objectFit: "cover" }}
                  src={loggedUserData.full_profile_image}
                  alt="..."
                />
                <div className="ms-3">
                  <h5 className="mb-1 fw-bold">{loggedUserData.student_name}</h5>
                  <p className="text-muted">{loggedUserData.dial_code + " " + loggedUserData.mobile }</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-9 d-flex justify-content-center justify-content-lg-end">
              <ul className="d-flex list-unstyled flex-wrap">
                <li>
                  <Link
                    className="btn btn-outline-danger rounded-pill fw-bold px-4"
                    to="/logout"
                  >
                    <i className="fa-solid fa-arrow-right-from-bracket me-2" />
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          

          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-2 g-lg-3 py-5">
            <div className="col">
              <div className="p-3 ">
                <button className="btn btn-lg btn-outline-light border-2 border-light text-dark w-100 text-start rounded-4">
                  <span className="me-4 text-primary">
                    <i className="bi bi-award" />
                  </span>
                  <span>My Certificates</span>
                </button>
              </div>
            </div>
            <div className="col">
              <div className="p-3">
                <button className="btn btn-lg btn-outline-light border-2 border-light text-dark w-100 text-start rounded-4">
                  <span className="me-4 text-primary">
                    <i className="bi bi-mortarboard" />
                  </span>
                  <span>Grade Sheet</span>
                </button>
              </div>
            </div>
            <div className="col">
              <div className="p-3">
                <button className="btn btn-lg btn-outline-light border-2 border-light text-dark w-100 text-start rounded-4">
                  <span className="me-4 text-primary">
                    <i className="bi bi-star-half" />
                  </span>
                  <span>Rate App</span>
                </button>
              </div>
            </div>
            <div className="col">
              <div className="p-3">
                <button className="btn btn-lg btn-outline-light border-2 border-light text-dark w-100 text-start rounded-4">
                  <span className="me-4 text-primary">
                    <i className="bi bi-share" />
                  </span>
                  <span>Refer to Friends</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}
