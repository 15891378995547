import React from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function PageNotFound() {
  return (
    
    <React.Fragment>
      <main className="flex-shrink-0">
        <Header/>
        <section className="py-5 bg-white" style={{ marginTop: "5rem" }}>
          <div className="container">
            <div className="px-4 py-5 my-5 text-center">
              <img
                className="d-block mx-auto mb-4"
                src="../assets/skillbary-theme/static-assets/svgs/start-learning.svg"
                alt="0"
              />
              <h1 className="display-5 fw-bold">404 Page Not Found!</h1>
            </div>
          </div>
        </section>
      </main>
      <Footer/>
    </React.Fragment>
  );
}
