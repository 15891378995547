import React, { useState, useContext, useEffect } from "react";
import { multiStepContext } from "../StepContext";
import axios from "axios";
import {
  apiEndPoint,
  splitGSettingString,
  CryptoJSAesEncrypt,
  CryptoJSAesDecrypt,
} from "../Helpers";
import Header from "../Header";
import Footer from "../Footer";
import CourseList from "./CourseList";

export default function CourseIndex() {
  const [loadCourses, setLoadCourses] = useState(null);

  const loggedInUser = localStorage.getItem("is_loagged_in");

  const generalSetting = localStorage.getItem("generalSetting");

  const { loginData, finalData } = useContext(multiStepContext);

  const [loggedUserData, setLoggedUserData] = useState(null);
  const [authorization, setAuthorization] = useState(null);
  const [action, setAction] = useState();

  //const { generalSetting, setGetGeneralSetting } = useContext(multiStepContext);

  var user_id = 0;

  useEffect(() => {
    
    if (loggedInUser === 'true' &&  finalData !== null) {
      setLoggedUserData(finalData.verified_user_data.user_data);
      setAuthorization(finalData.verified_user_data.Authorization);
      setAction(finalData.verified_user_data.action);
    }

    const getCoursesData = async () => {

      console.log("CourseIndex.js generalSetting", generalSetting);

      if (generalSetting) {

        const [api_user_name, api_token_name] = splitGSettingString(
          generalSetting,
          27
        );

        if (loggedInUser === 'true' &&  finalData !== null) {
          user_id = (finalData.verified_user_data.action == 'login')?finalData.verified_user_data.user_data.user_id:0;
        } else {
          user_id = 0;
        }


        const token = CryptoJSAesEncrypt(api_user_name, api_token_name);
        console.log("api_token_name token", token);

        const bodyParameters = {
          page: 1,
          per_page: 12,
          user_id: user_id,
          token: token,
          activity_by: "web",
        };

        const res = await axios({
          url: apiEndPoint() + "/mobile_app/courses/courses",
          data: bodyParameters,
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });

        console.log(res.data);

        if (res.data.status === 200) {
          const decryptData = CryptoJSAesDecrypt(res.data.token, res.data.data);

          if (decryptData) {
            const courseList = JSON.parse(decryptData);
            console.log("ourCourse courseList", courseList);
            setLoadCourses(courseList.list);
          }
        }
      }
    };
    getCoursesData();
  }, []);

  return (
    <>
      <main className="flex-shrink-0">
        <Header />
        {loadCourses ? (
          <CourseList courseData={loadCourses} />
        ) : (
          <div>
            <h2>Course Loading...</h2>
          </div>
        )}
      </main>
      <Footer />
    </>
  );
}
