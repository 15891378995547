import React, { useState, useContext, useEffect } from "react";
import { multiStepContext } from "../StepContext";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import htmlParse from "html-react-parser";

import {
  apiEndPoint,
  splitGSettingString,
  CryptoJSAesEncrypt,
  CryptoJSAesDecrypt,
  toastUniqueId,
  limitDesString,
} from "../Helpers";

export default function UserQualification() {
  const {
    currentStep,
    setStep,
    finalData,
    setFinalData,
    loginData,
    setLoginData,
  } = useContext(multiStepContext);

  const generalSetting = localStorage.getItem("generalSetting");
  const loggedInUser = localStorage.getItem("is_loagged_in");
  const mobile = localStorage.getItem("mobile");

  const [loggedUserData, setLoggedUserData] = useState(null);
  const [authorization, setAuthorization] = useState(null);
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const [loadQualification, setloadQualification] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (finalData !== null) {
      setLoggedUserData(finalData.verified_user_data.user_data);
      setAuthorization(finalData.verified_user_data.Authorization);
    }

    const loadQualificationData = () => {
      const generalSetting = localStorage.getItem("generalSetting");

      if (generalSetting) {
        const [api_user_name, api_token_name] = splitGSettingString(
          generalSetting,
          27
        );

        const token = CryptoJSAesEncrypt(api_user_name, api_token_name);
        console.log("api_token_name token", token);

        const formData = new FormData();
        formData.append("name", "all");
        formData.append("token", token);
        formData.append("activity_by", "web");

        axios
          .post(
            apiEndPoint() + "/mobile_app/account/qualification",
            formData,
            {}
          )
          .then((res) => {
            console.log("avatars qualification.js res", res.data.data);
            if (res.data.status === 200) {
              console.log("qualification.js status", res.data.status);

              const decryptData = CryptoJSAesDecrypt(
                res.data.token,
                res.data.data
              );

              console.log("qualification decryptData", decryptData);

              if (decryptData) {
                const qualificationList = JSON.parse(decryptData);
                console.log(
                  "qualification qualificationList",
                  qualificationList
                );
                setloadQualification(qualificationList);
                setIsLoading(false);
              }
            }
          })
          .catch((error) => {
            console.log("UserAvatar error", error);
          });
      }
    };

    loadQualificationData();
  }, []);

  const handleQualificationSelectionChange = (e) => {
    const target = e.target;
    if (target.checked) {
      setLoginData({ ...loginData, qualification_id: e.target.value });
      //console.log('handleAvatarSelectionChange called', e.target.value)
    }
  };

  const submitSignupData = (event) => {
    event.preventDefault()
    if (generalSetting) {
      const [api_user_name, api_token_name] = splitGSettingString(
        generalSetting,
        27
      );

      console.log("submitSignupData caleed");

      const token = CryptoJSAesEncrypt(api_user_name, api_token_name);
      console.log("api_token_name token", token);

      console.log("submitSignupData loginData", loginData);
      console.log("submitSignupData finalData", finalData);

      setIsLoading(true);
      setIsResetDisabled(true);

      const formData = new FormData();

      formData.append("dial_code", loginData["dial_code"]);
      formData.append("mobile", mobile);
      formData.append("name", loginData["name"]);
      formData.append("avatar_id", loginData["avatar_id"]);
      formData.append("qualification_id", loginData["qualification_id"]);
      formData.append("device_token", loginData["device_token"]);
      formData.append("token", token);
      formData.append("activity_by", "web");
      formData.append("version", "1.0");

      const res = axios({
        url: apiEndPoint() + "/mobile_app/account/register_new_user",
        data: formData,
        method: "post",
        headers: {
          Authorization: `Bearer ${finalData.verified_user_data.Authorization}`,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (res) {
          console.log(res.data);

          //alert("then");
          if (res.data.status === 200) {
            console.log("register_new_user res res.data.token", res.data.token);

            const decryptData = CryptoJSAesDecrypt(
              res.data.token,
              res.data.data
            );

            console.log("register_new_user res decryptData", decryptData);

            //alert("before if then");

            if (decryptData) {
              //alert("if then");
              const respData = JSON.parse(decryptData);
              console.log("register_new_user respData", respData);

              console.log(
                "register_new_user respData.user_data",
                respData.user_data
              );

              //alert("before toast");

              toast.success(res.data.message, {
                theme: "dark",
                position: "bottom-center",
                toastId: toastUniqueId(),
                onClose: () => {
                  
                  //alert("toast onClose");
                  console.log('respData.user_data ',respData.user_data);
                  
                  setIsLoading(false);
                  setIsResetDisabled(false);
                  setLoggedUserData(respData.user_data);
                  setAuthorization(respData.user_data.jwt_token);
                  setLoginData({ ...loginData, is_otp_verified: true });
                  /*setFinalData({
                    ...finalData,
                    verified_user_data:  {Authorization:respData.user_data.jwt_token,action:'login',user_data:respData},
                  });*/
                  //setFinalData({finalData,res.data.data});
                  localStorage.setItem("is_loagged_in", true);
                  localStorage.setItem("mobile", respData.user_data.mobile);
                  localStorage.setItem(
                    "is_refferal",
                    respData.user_data.is_refferal
                  );
                  localStorage.setItem("loginAction", "login");
                  localStorage.setItem(
                    "verified_user_data",
                    JSON.stringify({ verified_user_data: respData })
                  );

                  console.log("MobileOtp action click", "login");
                  console.log("login action", respData.user_data);
                  //alert("endedddd");
                  //Navigate("/dashboard");

                  //e.preventDefault();
                  //setStep(currentStep + 1);
                },
              });

              //setLoadMyCourses(respData.list);
            }
          } else if (res.data.status === 401) {
            //alert("then else");
            toast.error(res.data.message, {
              theme: "dark",
              position: "bottom-center",
              toastId: toastUniqueId(),
              onClose: () => {
                //e.preventDefault();
                setLoggedUserData(null);
                setAuthorization(null);
                //Navigate("/logout");
              },
            });
          }
        })
        .catch(function (error) {
          console.log("Error b", error.response.data);

          //alert("catch");

          if (error.response.status === 400) {
            //alert("catch if");
            toast.info(error.response.data.message, {
              theme: "dark",
              position: "bottom-center",
              toastId: toastUniqueId(),
              onClose: () => {
                setLoggedUserData(null);
                setAuthorization(null);
                //Navigate("/logout");
              },
            });
          } else {
            //alert("catch else");
            toast.error(error.response.data.message, {
              theme: "dark",
              position: "bottom-center",
              toastId: toastUniqueId(),
              onClose: () => {
                setLoggedUserData(null);
                setAuthorization(null);
                //Navigate("/logout");
              },
            });
          }
        });

      //alert("end");

      setIsLoading(false);
    }
  };

  return (
    <>
      {loadQualification ? (
        <section className="py-5 bg-white" style={{ marginTop: "5rem" }}>
          <div className="container">
            <div className="px-4 py-5 my-5">
              <div className="d-flex">
                <div
                  className="backbtn"
                  role="button"
                  onClick={() => {
                    setStep(currentStep - 1);
                  }}
                >
                  <i className="bi bi-arrow-left fs-4 fw-bold" />
                </div>
                <div className="ms-3">
                  <h5 className="mb-1">What Are You Pursuing</h5>
                  <p className="text-muted">
                    <span>We want to know what you made off</span>
                  </p>
                </div>
              </div>
              <div className="py-5">
                <form>
                  <div className="container text-center">
                    <div className="container">
                      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-2 g-lg-3">
                        {isLoading ? (
                          <div>Loading ...</div>
                        ) : (
                          loadQualification.map((qualification) => (
                            <div className="col" key={qualification.id}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="select-qualification"
                                id={`qualification-${qualification.icon_code}-outlined`}
                                autoComplete="off"
                                value={qualification.id}
                                checked={
                                  loginData["qualification_id"] ===
                                  qualification.id
                                    ? true
                                    : false
                                }
                                onChange={handleQualificationSelectionChange}
                              />

                              <label
                                className="btn btn-outline-primary w-100 text-start rounded-4"
                                htmlFor={`qualification-${qualification.icon_code}-outlined`}
                              >
                                <i
                                  className={`${qualification.fa_icon} me-2`}
                                />{" "}
                                {qualification.name}
                              </label>
                            </div>
                          ))
                        )}
                      </div>
                      <div className="row g-4">
                        <div className="col py-4 text-end">
                          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button
                              //onClick={() => setStep(currentStep + 1)}
                              onClick={submitSignupData}
                              className="btn btn-primary rounded-pill btn-lg px-4 gap-3"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div>
          <h2>Qualification Loading...</h2>
        </div>
      )}
    </>
  );
}
