import React from "react";
import SectionAccordion from "./SectionAccordion";
import SectionResources from "./SectionResources";

export default function CurriculumAccordion(props) {
  //console.log("accData CurriculumAccordion here", props.accData);
  let accData = props.accData;

  return (
    <>
    {accData ? (
      <div className="accordion" id="curriculum-accordion">
        {accData.map((modules, module_index) => (
        <div className="accordion-section" key={module_index}>
          <h2 className="accordion-header" id={"headingOne"+module_index}>
            <button
              className={"accordion-button " + (module_index === 0 ? '' : 'collapsed')}
              
              style={{
                color: "#fff",
                background: "transparent",
              }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={"#collapseOne"+module_index}
              aria-expanded={(module_index === 0)?"true":"false"}
              aria-controls={"collapseOne"+module_index}
            >
              {modules.section_name}
            </button>
          </h2>
          
          <div
            id={"collapseOne"+module_index}
            className={"accordion-collapse collapse " + (module_index === 0 ? 'show' : '')}
            aria-labelledby={"headingOne"+module_index}
            data-bs-parent="#curriculum-accordion"
          >
            <div className="accordion-body">
                {(modules.section_type === 'curriculum')?<SectionAccordion accSecData={modules.modules_data} />:null}
                
            </div>
          </div>
        </div>
       ))}
      </div>
      ) : (
        <div>
          <h6 className="text-white">Curriculum Under Development...</h6>
        </div>
      )}
    </>
  );
}
