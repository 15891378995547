import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { multiStepContext } from "../StepContext";

import {
  apiEndPoint,
  splitGSettingString,
  CryptoJSAesEncrypt,
  toastUniqueId,
} from "../Helpers";

// formLoginData,setFormLoginData
export default function NewMobileNumber(props) {
  const generalSetting = localStorage.getItem("generalSetting");

  const { currentStep, setStep, loginData, setLoginData } =
    useContext(multiStepContext);

  const [isLoading, setIsLoading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const initialText = "Continue";
  const [buttonText, setButtonText] = useState(initialText);

  const handleRequestOtpSubmit = (e) => {
    console.log("handleRequestOtpSubmit clicked");
    e.preventDefault();
    setIsDisabled(true);
    setButtonText("Loading...");
    if (validateMobileNumber()) {
      apiRequestOtpLogin();
      //setStep(currentStep + 1);
    } else {
      setIsDisabled(false);
      setButtonText(initialText);
    }
  };

  const apiRequestOtpLogin = async () => {
    if (generalSetting) {
      const [api_user_name, api_token_name] = splitGSettingString(
        generalSetting,
        27
      );

      const token = CryptoJSAesEncrypt(api_user_name, api_token_name);
      console.log("api_token_name token", token);

      setIsLoading(true);
      setIsDisabled(true);

      const formData = new FormData();

      formData.append("dial_code", loginData["dial_code"]);
      formData.append("mobile", loginData["mobile"]);
      formData.append("request_type", loginData["request_type"]);
      formData.append("token", token);
      formData.append("activity_by", "web");

      axios
        .post(apiEndPoint() + "/mobile_app/account/login", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            // Content-Type: application/json; charset=utf-8
          },
        })
        .then((res) => {
          console.log("account/login res", res);

          if (res.data.status === 200) {
            toast.success(res.data.message, {
              theme: "dark",
              position: "bottom-center",
              toastId: toastUniqueId(),
              onClose: () => {
                //e.preventDefault();
                setStep(currentStep + 1);
                setIsDisabled(false);
                setButtonText(initialText);
              },
            });
          }
        })
        .catch((error) => {
          //console.log("Error a", error);

          if (error.response) {
            toast.error(error.response.data.message, {
              theme: "dark",
              position: "bottom-center",
              toastId: toastUniqueId(),
              onClose: () => {
                setIsDisabled(false);
                setButtonText(initialText);
                loginData["mobile"] = null;
                setLoginData({ ...loginData, mobile: null });
              },
            });
          } else if (error.request) {
            console.log("Error e", error.request);

            toast.error("Some Technical TIMED_OUT Error", {
              theme: "dark",
              position: "bottom-center",
              toastId: toastUniqueId(),
              onClose: () => {
                setIsDisabled(false);
                setButtonText(initialText);
                loginData["mobile"] = null;
                setLoginData({ ...loginData, mobile: null });
              },
            });
          } else {
            console.log("Error f", error.message);

            toast.error("Some Technical TIMED_OUT Error", {
              theme: "dark",
              position: "bottom-center",
              toastId: toastUniqueId(),
              onClose: () => {
                setIsDisabled(false);
                setButtonText(initialText);
                loginData["mobile"] = null;
                setLoginData({ ...loginData, mobile: null });
              },
            });
          }
        });
    }
  };

  const validateMobileNumber = (e) => {
    let mobile = loginData["mobile"];
    console.log("validateMobileNumber mobile", mobile);
    let errors = {};
    let isValid = true;
    if (!mobile) {
      isValid = false;
      errors["mobile"] = "Please enter your Mobile Number.";
    }

    if (typeof mobile !== "undefined") {
      var pattern = new RegExp(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
      );
      if (!pattern.test(mobile)) {
        isValid = false;
        errors["mobile"] = "Please Enter Number Only";
      } else if (mobile.length !== 10) {
        isValid = false;
        errors["mobile"] = "Please enter valid  Mobile Number.";
      }
    }

    //toast(errors["mobile"], { autoClose: 15000 });

    console.log("validateMobileNumber isValid", isValid);

    if (!isValid) {
      toast.error(errors["mobile"], {
        theme: "dark",
        position: "bottom-center",
        toastId: toastUniqueId(),
      });
    }

    return isValid;
  };

  return (
    <>
      <section>
        <div className="container">
          <div>
            <div className="d-flex">
              <div>
                <h5 className="mb-1">
                  Enter Your Mobile Number
                  <br /> to Start Learning
                </h5>
                <p className="text-muted">
                  We will send you a confirmation code
                </p>
              </div>
            </div>
            <div>
              <form onSubmit={handleRequestOtpSubmit}>
                <div className="hstack hstack-sm gap-3">
                  <label>
                    <img
                      src="assets/skillbary-theme/static-assets/svgs/india-flag.svg"
                      alt="..."
                    />
                  </label>
                  <label className="fw-bold">+91</label>
                  <input
                    className="form-control-lg me-auto mobile-no"
                    maxLength={10}
                    type="text"
                    placeholder="8400800107"
                    aria-label="8400800107"
                    value={loginData["mobile"] || ""}
                    onChange={(e) =>
                      setLoginData({
                        ...loginData,
                        mobile: e.target.value,
                        dial_code: "+91",
                      })
                    }
                  />
                </div>
                <div className="hstack gap-3">
                  <div className="form-check form-switch p-0 mt-4">
                    <label
                      className="form-check-label fw-bold"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Have a Referral Code?
                    </label>
                    <input
                      className="form-check-input reffer-imput-css"
                      style={{ marginLeft: "2.5em", float: "right" }}
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      value={loginData["is_refferal"]}
                      defaultChecked={loginData["is_refferal"]}
                      onChange={(e) =>
                        setLoginData({
                          ...loginData,
                          is_refferal: !loginData["is_refferal"],
                        })
                      }
                    />
                  </div>
                </div>
                <div className="d-grid gap-2 col-12 mx-auto mt-4">
                  <button
                    className="btn btn-primary rounded-pill "
                    disabled={isDisabled}
                  >
                    {buttonText}
                  </button>
                  <button
                    type="button"
                    className="btn btn-light rounded-pill"
                    onClick={props.close}
                    style={{ backgroundImage: "none", boxShadow: "none" }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
