
import ReactDOM from "react-dom/client";
import './index.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import StepContext from "./StepContext";



const root = ReactDOM.createRoot(document.getElementById("skillbary-root"));
root.render(
  
  <>
      <BrowserRouter>
        <StepContext>
          <App />
        </StepContext>
      </BrowserRouter>
    
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
