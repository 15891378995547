import htmlParse from "html-react-parser";
import axios from "axios";

var CryptoJS = require("crypto-js");

export function splitGSettingString(str, index) {
  const result = [str.slice(0, index), str.slice(index)];

  return result;
}

export function addSpaceToString(str, max) {
  let len = str.length;
  let count = max - len;
  console.log('addSpaceToString ',str + ' count '+count)
  let counter = (count < 8)?7:count;
  let emptyStr = "";
  for (var i = 0; i < counter; i++) {
    emptyStr = emptyStr + "s ";
  }
  return emptyStr;
}

export function CryptoJSAesEncrypt(passphrase, plain_text) {
  // CryptoJS: javascript encrypt -> PHP openssl decrypt

  var salt = CryptoJS.lib.WordArray.random(256);
  var iv = CryptoJS.lib.WordArray.random(16);

  //for more random entropy can use : https://github.com/wwwtyro/cryptico/blob/master/random.js instead CryptoJS random() or another js PRNG

  var key = CryptoJS.PBKDF2(passphrase, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: 64 / 8,
    iterations: 999,
  });

  var encrypted = CryptoJS.AES.encrypt(plain_text, key, { iv: iv });

  var data = {
    ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
    salt: CryptoJS.enc.Hex.stringify(salt),
    iv: CryptoJS.enc.Hex.stringify(iv),
  };

  return JSON.stringify(data);
}

export function CryptoJSAesDecrypt(passphrase, encrypted_json_string) {
  // CryptoJS: PHP openssl encrypt -> javascript decrypt
  var obj_json = JSON.parse(encrypted_json_string);

  var encrypted = obj_json.ciphertext;
  var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
  var iv = CryptoJS.enc.Hex.parse(obj_json.iv);

  var key = CryptoJS.PBKDF2(passphrase, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: 64 / 8,
    iterations: 999,
  });

  var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function toastUniqueId(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function apiEndPoint() {
  var api = "https://dev.skillbary.com"; //"http://localhost/skillbary-git"; //
  return api;
}

export function limitAppDesString(stringValue, limit) {
  if (stringValue.length > limit) {
    return stringValue.substring(0, limit) + "...";
  } else {
    return stringValue;
  }
}

export function limitDesString(stringValue, limit) {
  //stringValue = stringValue.substring(0,100)+"..."
  //console.log('limitDesString stringValue',stringValue);
  var reactCode = htmlParse(stringValue);

  if (reactCode.length > 0) {
    //console.log('reactCode.length 0 > if 1',reactCode[0].props.children);

    if (reactCode[0].props.children.length > 0) {
      console.log("reactCode.length 0 > if 2", reactCode[0].props.children);

      if (reactCode[0].props.children[0].props.children.length > 0) {
      } else {
        return (
          reactCode[0].props.children.props.children[0].substring(0, limit) +
          "..."
        );
      }
      //return reactCode[0].props.children[0];
    } else {
      //console.log('reactCode.length 0 > else 2',reactCode[0].props.children.props.children);
      return (
        reactCode[0].props.children.props.children.substring(0, limit) + "..."
      );
    }
  } else {
    //console.log('reactCode.length 0 > else 1',reactCode.props.children);
    return reactCode.props.children.substring(0, limit) + "...";
  }
}
