import React from "react";
function Banner() {

    const bannerDivCss={
        
        marginTop: '3rem',
        backgroundPosition:'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url("assets/skillbary-theme/static-assets/hero-section/hero-bg.png"), linear-gradient(180deg, #5AA3FF 0%, #6674FF 100%)'
        };

  return (
    <React.Fragment>
      <section  style={bannerDivCss}>
                <div className="container">


                <div className=" row align-items-center ">
            <div className="mt-5 col-12 col-lg-6 text-white">
              <p>
                I came to this platform with a dream of becoming a Product
                Manager. I knew that I had the skills and the passion, but I
                didn't know how to get started.
              </p>
              <p>
                Skillbary helped me every step of the way. They helped me with
                my resume, they gave me mock interviews, and they even helped me
                find my first internship.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <div className="text-center">
                <img
                  src="assets/skillbary-theme/static-assets/banner/student-testimoney.png"
                  className="img-fluid w-50"
                  alt="..."
                />
              </div>
            </div>
          </div>

                </div>
            </section>

    </React.Fragment>
  );
}

export default Banner;
