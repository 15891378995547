import React, { useState, useContext, useEffect } from "react";
import { multiStepContext } from "../StepContext";
import { toastUniqueId } from "../Helpers";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate } from "react-router-dom";

import axios from "axios";

import {
  apiEndPoint,
  splitGSettingString,
  CryptoJSAesEncrypt,
  CryptoJSAesDecrypt,
} from "../Helpers";

import { useParams } from "react-router-dom";
import htmlParse from "html-react-parser";
import Header from "../Header";
import Footer from "../Footer";
import CurriculumAccordion from "./CurriculumAccordion";

export default function CourseDetailsPage() {
  const generalSetting = localStorage.getItem("generalSetting");

  const navigate = useNavigate();

  const { slug } = useParams();

  const { loginData, finalData } = useContext(multiStepContext);
  const [loggedUserData, setLoggedUserData] = useState(null);

  const [loadCourseDetails, setLoadCourseDetails] = useState(null);

  const [isDisabled, setIsDisabled] = useState(false);

  const initialText = "Enroll Now";
  const [buttonText, setButtonText] = useState(initialText);

  var user_id = 0;

  useEffect(() => {
    const getCoursesDatails = async () => {
      if (generalSetting) {
        const [api_user_name, api_token_name] = splitGSettingString(
          generalSetting,
          27
        );

        if (finalData !== null) {
          user_id = finalData.verified_user_data.user_data.user_id;
        } else {
          user_id = 0;
        }

        const token = CryptoJSAesEncrypt(api_user_name, api_token_name);

        const formData = new FormData();

        formData.append("token", token);
        formData.append("activity_by", "web");
        formData.append(
          "user_id",
          finalData.verified_user_data.user_data.user_id
        );
        formData.append("course_id", slug);

        axios
          .post(
            apiEndPoint() + "/mobile_app/courses/access_course_details",
            formData,
            {
              headers: {
                Authorization: `Bearer ${finalData.verified_user_data.Authorization}`,
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            console.log("access_course_details res", res);

            if (res.data.status === 200) {
              console.log(res.data);

              const decryptData = CryptoJSAesDecrypt(res.data.token, res.data.data);

              if (decryptData) {
                const respCourseDetails = JSON.parse(decryptData);
                console.log("ourCourse respCourseDetails", respCourseDetails);
                setLoadCourseDetails(respCourseDetails);
              }

              // setLoadCourseDetails(res.data.data);

              toast.success(res.data.message, {
                theme: "dark",
                position: "bottom-center",
                toastId: toastUniqueId(),
                onClose: () => {
                  //e.preventDefault();
                  //setIsDisabled(false);
                  //setButtonText(initialText);
                  //navigate("/my-courses");
                },
              });
            }
          })
          .catch((error) => {
            console.log("Error a", error);

            if (error.response) {
              toast.error(error.response.data.message, {
                theme: "dark",
                position: "bottom-center",
                toastId: toastUniqueId(),
                onClose: () => {
                  //setIsDisabled(false);
                  //setButtonText(initialText);
                },
              });
            } else if (error.request) {
              console.log("Error e", error.request);

              toast.error("Some Technical TIMED_OUT Error", {
                theme: "dark",
                position: "bottom-center",
                toastId: toastUniqueId(),
                onClose: () => {
                  //setIsDisabled(false);
                  //setButtonText(initialText);
                },
              });
            } else {
              console.log("Error f", error.message);

              toast.error("Some Technical TIMED_OUT Error", {
                theme: "dark",
                position: "bottom-center",
                toastId: toastUniqueId(),
                onClose: () => {
                  //setIsDisabled(false);
                  //setButtonText(initialText);
                  //loginData["mobile"] = null;
                  //setLoginData({ ...loginData, mobile: null });
                },
              });
            }
          });
      }
    };
    getCoursesDatails();
  }, [slug]);

  const handleAssignCourse = () => {
    console.log("handleAssignCourse clicked");
    setIsDisabled(true);
    setButtonText("Loading...");

    if (apiRequestAssignCourse()) {
      console.log("handleAssignCourse passed");
      //apiRequestOtpLogin();
      //setStep(currentStep + 1);
    } else {
      console.log("handleAssignCourse falied");
      setIsDisabled(false);
      setButtonText(initialText);
    }
  };

  const apiRequestAssignCourse = async () => {
    console.log(
      "apiRequestAssignCourse from Authorization",
      finalData.verified_user_data.Authorization
    );
    const formData = new FormData();

    //console.log('apiRequestAssignCourse finalData', finalData);
    //console.log('apiRequestAssignCourse loadCourseDetails', loadCourseDetails);

    formData.append("activity_by", "web");
    formData.append("user_id", finalData.verified_user_data.user_data.user_id);
    formData.append("course_id", loadCourseDetails.id);

    axios
      .post(apiEndPoint() + "/mobile_app/courses/start_course", formData, {
        headers: {
          Authorization: `Bearer ${finalData.verified_user_data.Authorization}`,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("apiRequestAssignCourse res", res);

        if (res.data.status === 200) {
          toast.success(res.data.message, {
            theme: "dark",
            position: "bottom-center",
            toastId: toastUniqueId(),
            onClose: () => {
              //e.preventDefault();

              setIsDisabled(false);
              setButtonText(initialText);
              navigate("/my-courses");
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error a", error);

        if (error.response) {
          toast.error(error.response.data.message, {
            theme: "dark",
            position: "bottom-center",
            toastId: toastUniqueId(),
            onClose: () => {
              setIsDisabled(false);
              setButtonText(initialText);
            },
          });
        } else if (error.request) {
          console.log("Error e", error.request);

          toast.error("Some Technical TIMED_OUT Error", {
            theme: "dark",
            position: "bottom-center",
            toastId: toastUniqueId(),
            onClose: () => {
              setIsDisabled(false);
              setButtonText(initialText);
            },
          });
        } else {
          console.log("Error f", error.message);

          toast.error("Some Technical TIMED_OUT Error", {
            theme: "dark",
            position: "bottom-center",
            toastId: toastUniqueId(),
            onClose: () => {
              setIsDisabled(false);
              setButtonText(initialText);
              //loginData["mobile"] = null;
              //setLoginData({ ...loginData, mobile: null });
            },
          });
        }
      });
  };

  return (
    <>
      <main className="flex-shrink-0">
        <Header />
        {loadCourseDetails ? (
          <div>
            <section className="pt-5" style={{ marginTop: "3rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col mb-5 mb-lg-0 text-left">
                    <div className="d-flex">
                      <Link className="backbtn" role="button" to="/courses">
                        <i className="bi bi-arrow-left fs-4 fw-bold" />
                      </Link>
                      <div className="ms-3">
                        <h5 className="mb-1">Enter Your Referral Code</h5>
                        <p className="text-muted">
                          <span>
                            Once entered, you will be eligible for a Referral
                            discount of up tp Rs. 80,000.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              style={{
                marginBottom: "5rem",
                //backgroundPosition: "center center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage:
                  "url(" + loadCourseDetails.full_featured_image + ")",
              }}
            >
              <div className="container">
                <div className="row ">
                  <div className="col banner-spacer banner-md-spacer" />
                </div>
                <div className="row">
                  <div className="col position-relative">
                    <div
                      className="bg-white border border-3 border-light rounded-4 p-3 p-md-4 mx-0 mx-md-5"
                      style={{ transform: "translate(0, 50%)" }}
                    >
                      <div className="row mb-4">
                        <div className="col-12 col-lg-9 mb-5 mb-lg-0 text-center text-lg-left">
                          <span className="fs-4 fw-bold me-2">
                            {htmlParse(loadCourseDetails.course_title)}
                          </span>
                          <span className="me-2">
                            <img
                              src="../assets/skillbary-theme/static-assets/courses/star-rating.svg"
                              style={{ width: 24 }}
                              className="p-0 m-0 text-muted"
                              alt="..."
                            />
                          </span>
                          <span className="me-2 text-muted fw-bold">4.7K</span>
                        </div>
                        <div className="col-12 col-lg-3 d-flex justify-content-center justify-content-lg-end">
                          <img
                            className="img-fluid"
                            src="../assets/skillbary-theme/static-assets/partners/harvard.svg"
                            alt="..."
                            width={106}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="text-muted m-0">
                            {htmlParse(loadCourseDetails.web_description)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="py-5 ">
              <div className="container">
                <div
                  className="row text-center mt-5 mt-md-0"
                  style={{ textAlign: "center" }}
                >
                  <div className="col-12 col-lg-4 py-4">
                    <span className="text-dark me-2">
                      <i className="bi bi-camera-reels-fill" />
                    </span>
                    <span className="fw-bold">
                      Live+ Recorded Masterclasses
                    </span>
                  </div>
                  <div className="col-12 col-lg-4 py-4">
                    <span className="text-dark me-2">
                      <i className="bi bi-book-half" />
                    </span>
                    <span className="fw-bold">
                      Personalised Executive Coaching
                    </span>
                  </div>
                  <div className="col-12 col-lg-4 py-4">
                    <span className="text-dark me-2">
                      <i className="bi bi-diagram-3" />
                    </span>
                    <span className="fw-bold">Accomplished Peer Network</span>
                  </div>
                </div>
              </div>
            </section>

            <section className="py-5 bg-white">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-12">
                    <h3 className="fw-bold">Syllabus</h3>
                  </div>
                </div>
                <div className="row row-cols-2 row-cols-md-4 g-4">
                  <div className="col">
                    <div className="card rounded-4 h-100">
                      <div className="card-body">
                        <img
                          src="../assets/skillbary-theme/static-assets/our-features/recorded-lecture.svg"
                          className="card-img-top w-25"
                          alt="..."
                        />
                        <h5 className="card-title my-4">Recorded Lectures</h5>
                        <p className="card-text">
                          Learn anytime anywhere with self paced videos by
                          subject matter experts to clear your fundamental
                          concepts.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card rounded-4 h-100">
                      <div className="card-body">
                        <img
                          src="../assets/skillbary-theme/static-assets/our-features/case-studies.svg"
                          className="card-img-top w-25"
                          alt="..."
                        />
                        <h5 className="card-title my-4">Case Studies</h5>
                        <p className="card-text">
                          Gain practical knowledge with case studies and
                          assignments to polish your skills.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card rounded-4 h-100">
                      <div className="card-body">
                        <img
                          src="../assets/skillbary-theme/static-assets/our-features/internship.svg"
                          className="card-img-top w-25"
                          alt="..."
                        />
                        <h5 className="card-title my-4">Internship Support</h5>
                        <p className="card-text">
                          Gain corporate experience and increase your odds of
                          getting a secure job.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card rounded-4 h-100">
                      <div className="card-body">
                        <img
                          src="../assets/skillbary-theme/static-assets/our-features/masterclass.svg"
                          className="card-img-top w-25"
                          alt="..."
                        />
                        <h5 className="card-title my-4">Masterclass</h5>
                        <p className="card-text">
                          Gain extensive knowledge from industry experts and
                          learn from their experiences.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="py-5 " style={{ background: "#4D5968" }}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-white fs-5 my-4">
                      Program Fee
                    </h5>
                    <div
                      className="card mb-4 rounded-4 border-4 text-white"
                      style={{ background: "#4D5968", borderColor: "#fff" }}
                    >
                      <div
                        className="card-body py-0 d-flex flex-column"
                        style={{ height: "33rem" }}
                      >
                        <img
                          src="../assets/skillbary-theme/static-assets/courses/program-fee-bg.svg"
                          className="img-fluid w-50 mx-auto d-block"
                          alt="..."
                        />
                        <div className="mt-auto">
                          {(() => {
                            if (loadCourseDetails.is_enrolled === 1) {
                              return (
                                <>
                                  <button
                                    href="checkout.html"
                                    className="btn btn-primary rounded-pill px-4 fw-bold w-100 mb-4"
                                  >
                                    Resume Course
                                  </button>
                                </>
                              );
                            } else {
                              if (loadCourseDetails.price !== "0") {
                                if (loadCourseDetails.is_sale === "0") {
                                  return (
                                    <>
                                      <h5 className="my-4">
                                        <span>
                                          Rs.{loadCourseDetails.price}/-
                                        </span>
                                      </h5>

                                      <p>
                                        EMI Options Available. Starting at INR
                                        5,432 / month. The credit facility is
                                        provided by a third party credit
                                        facility provider and any arrangement
                                        with such third party is outside
                                        skillbrew purview
                                      </p>

                                      <button
                                        href="checkout.html"
                                        className="btn btn-primary rounded-pill px-4 fw-bold w-100 mb-4"
                                      >
                                        Enroll Now
                                      </button>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <h5 className="my-4">
                                        <span
                                          className="text-decoration-line-through text-secondary me-2"
                                          style={{
                                            textDecorationColor:
                                              "initial !important",
                                          }}
                                        >
                                          Rs.{loadCourseDetails.price}/-
                                        </span>
                                        <span className="text-white">
                                          Rs.{loadCourseDetails.sale_price}/-
                                        </span>
                                      </h5>

                                      <p>
                                        EMI Options Available. Starting at INR
                                        5,432 / month. The credit facility is
                                        provided by a third party credit
                                        facility provider and any arrangement
                                        with such third party is outside
                                        skillbrew purview
                                      </p>

                                      <button
                                        href="checkout.html"
                                        className="btn btn-primary rounded-pill px-4 fw-bold w-100 mb-4"
                                      >
                                        Enroll Now
                                      </button>
                                    </>
                                  );
                                }
                              } else {
                                return (
                                  <>
                                    <h5 className="my-4">
                                      <span>FREE</span>
                                    </h5>

                                    <button
                                      type="submit"
                                      onClick={handleAssignCourse}
                                      disabled={isDisabled}
                                      className="btn btn-primary rounded-pill px-4 fw-bold w-100 mb-4"
                                    >
                                      {buttonText}
                                    </button>
                                  </>
                                );
                              }
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <h5 className="card-title text-white fs-5 my-4">
                      Curriculum
                    </h5>
                    <div
                      className="card mb-4 border-0 "
                      style={{ background: "#4D5968" }}
                    >
                      <div
                        className="card-body p-0 border-4 overflow-auto"
                        style={{ height: "33rem" }}
                      >
                        <CurriculumAccordion
                          accData={loadCourseDetails.curriculum}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="py-5 bg-white">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-12">
                    <h3 className="fw-bold">Career Impact</h3>
                  </div>
                </div>
                <div className="row row-cols-2 row-cols-md-4 g-4">
                  <div className="col">
                    <div className="card rounded-4 h-100">
                      <div className="card-body text-center">
                        <img
                          src="../assets/skillbary-theme/static-assets/career-impact/live-projects.svg"
                          className="card-img-top w-50"
                          alt="..."
                        />
                        <h6 className="card-title my-4">Live Projects</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card rounded-4 h-100">
                      <div className="card-body text-center">
                        <img
                          src="../assets/skillbary-theme/static-assets/career-impact/internship.svg"
                          className="card-img-top w-50"
                          alt="..."
                        />
                        <h6 className="card-title my-4">Internship Support</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card rounded-4 h-100">
                      <div className="card-body text-center">
                        <img
                          src="../assets/skillbary-theme/static-assets/career-impact/certificate.svg"
                          className="card-img-top w-50"
                          alt="..."
                        />
                        <h6 className="card-title my-4">
                          Certification by AICT
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card rounded-4 h-100">
                      <div className="card-body text-center">
                        <img
                          src="../assets/skillbary-theme/static-assets/career-impact/job-garentee.svg"
                          className="card-img-top w-50"
                          alt="..."
                        />
                        <h6 className="card-title my-4">Job Guarantee</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="py-5 bg-white">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-12">
                    <h3 className="fw-bold">Why Choose Us?</h3>
                  </div>
                </div>
                <div className="row row-cols-2 row-cols-md-4 g-4">
                  <div className="col">
                    <div className="card rounded-4 h-100">
                      <div className="card-body text-center">
                        <img
                          src="../assets/skillbary-theme/static-assets/career-impact/live-projects.svg"
                          className="card-img-top w-50"
                          alt="..."
                        />
                        <h6 className="card-title my-4">Live Projects</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card rounded-4 h-100">
                      <div className="card-body text-center">
                        <img
                          src="../assets/skillbary-theme/static-assets/career-impact/internship.svg"
                          className="card-img-top w-50"
                          alt="..."
                        />
                        <h6 className="card-title my-4">Internship Support</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card rounded-4 h-100">
                      <div className="card-body text-center">
                        <img
                          src="../assets/skillbary-theme/static-assets/career-impact/certificate.svg"
                          className="card-img-top w-50"
                          alt="..."
                        />
                        <h6 className="card-title my-4">
                          Certification by AICT
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card rounded-4 h-100">
                      <div className="card-body text-center">
                        <img
                          src="../assets/skillbary-theme/static-assets/career-impact/job-garentee.svg"
                          className="card-img-top w-50"
                          alt="..."
                        />
                        <h6 className="card-title my-4">Job Guarantee</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="py-5 bg-white">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-12">
                    <h3 className="fw-bold">Instructor</h3>
                  </div>
                </div>
                <div className="row row-cols-2 row-cols-md-6 g-4">
                  <div className="col py-2">
                    <div className="card h-100 rounded-4 text-center">
                      <div className="card-body">
                        <img
                          src="../assets/skillbary-theme/static-assets/alumnis/rajat-chauhan.png"
                          className="img-fluid rounded-circle"
                          alt="..."
                        />
                        <p className="card-text text-gray py-4">
                          Rajat Chauhan
                        </p>
                      </div>
                      <div className="card-footer bg-transparent border-0">
                        <img
                          src="../assets/skillbary-theme/static-assets/partners/harvard.svg"
                          className="card-img-top w-50 pb-4"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col py-2">
                    <div className="card h-100 rounded-4 text-center">
                      <div className="card-body">
                        <img
                          src="../assets/skillbary-theme/static-assets/alumnis/priya-gehlot.png"
                          className="img-fluid rounded-circle"
                          alt="..."
                        />
                        <p className="card-text text-gray py-4">Priya Gehlot</p>
                      </div>
                      <div className="card-footer bg-transparent border-0">
                        <img
                          src="../assets/skillbary-theme/static-assets/partners/harvard.svg"
                          className="card-img-top w-50 pb-4"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col py-2">
                    <div className="card h-100 rounded-4 text-center">
                      <div className="card-body">
                        <img
                          src="../assets/skillbary-theme/static-assets/alumnis/rajat-chauhan.png"
                          className="img-fluid rounded-circle"
                          alt="..."
                        />
                        <p className="card-text text-gray py-4">
                          Rajat Chauhan
                        </p>
                      </div>
                      <div className="card-footer bg-transparent border-0">
                        <img
                          src="../assets/skillbary-theme/static-assets/partners/harvard.svg"
                          className="card-img-top w-50 pb-4"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col py-2">
                    <div className="card h-100 rounded-4 text-center">
                      <div className="card-body">
                        <img
                          src="../assets/skillbary-theme/static-assets/alumnis/priya-gehlot.png"
                          className="img-fluid rounded-circle"
                          alt="..."
                        />
                        <p className="card-text text-gray py-4">Priya Gehlot</p>
                      </div>
                      <div className="card-footer bg-transparent border-0">
                        <img
                          src="../assets/skillbary-theme/static-assets/partners/harvard.svg"
                          className="card-img-top w-50 pb-4"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col py-2">
                    <div className="card h-100 rounded-4 text-center">
                      <div className="card-body">
                        <img
                          src="../assets/skillbary-theme/static-assets/alumnis/rajat-chauhan.png"
                          className="img-fluid rounded-circle"
                          alt="..."
                        />
                        <p className="card-text text-gray py-4">
                          Rajat Chauhan
                        </p>
                      </div>
                      <div className="card-footer bg-transparent border-0">
                        <img
                          src="../assets/skillbary-theme/static-assets/partners/harvard.svg"
                          className="card-img-top w-50 pb-4"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col py-2">
                    <div className="card h-100 rounded-4 text-center">
                      <div className="card-body">
                        <img
                          src="../assets/skillbary-theme/static-assets/alumnis/priya-gehlot.png"
                          className="img-fluid rounded-circle"
                          alt="..."
                        />
                        <p className="card-text text-gray py-4">Priya Gehlot</p>
                      </div>
                      <div className="card-footer bg-transparent border-0">
                        <img
                          src="../assets/skillbary-theme/static-assets/partners/harvard.svg"
                          className="card-img-top w-50 pb-4"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="py-5 bg-white">
              <div className="container">
                <div className="row mb-0">
                  <div className="col-12">
                    <h3 className="fw-bold">Skillbary Learner Reviews</h3>
                  </div>
                </div>
              </div>
            </section>

            <section className="bg-white" style={{ marginTop: "0rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col position-relative">
                    <div
                      style={{ transform: "translate(0%, -50%) !important" }}
                    >
                      <div className="row gap-3">
                        <div className="col bg-white border border-3 border-light rounded-4">
                          <p className="text-muted m-0">
                            In publishing and graphic design, Lorem ipsum is a
                            placeholder text commonly used to demonstrate the
                            visual form of a document or a typeface without
                            relying on meaningful content. Lorem ipsum may be
                            used as a placeholder before final copy is
                            available.
                          </p>
                        </div>
                        <div className="col bg-white border border-3 border-light rounded-4 ">
                          <p className="text-muted m-0">
                            In publishing and graphic design, Lorem ipsum is a
                            placeholder text commonly used to demonstrate the
                            visual form of a document or a typeface without
                            relying on meaningful content. Lorem ipsum may be
                            used as a placeholder before final copy is
                            available.
                          </p>
                        </div>
                        <div className="col bg-white border border-3 border-light rounded-4 ">
                          <p className="text-muted m-0">
                            In publishing and graphic design, Lorem ipsum is a
                            placeholder text commonly used to demonstrate the
                            visual form of a document or a typeface without
                            relying on meaningful content. Lorem ipsum may be
                            used as a placeholder before final copy is
                            available.
                          </p>
                        </div>
                        <div className="col bg-white border border-3 border-light rounded-4 ">
                          <p className="text-muted m-0">
                            In publishing and graphic design, Lorem ipsum is a
                            placeholder text commonly used to demonstrate the
                            visual form of a document or a typeface without
                            relying on meaningful content. Lorem ipsum may be
                            used as a placeholder before final copy is
                            available.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <h1>Loading...</h1>
        )}
      </main>
      <Footer />
    </>
  );
}
