import { keyboard } from "@testing-library/user-event/dist/keyboard";
import React, { useState, useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { multiStepContext } from "../src/StepContext";
import Form from "./Login/Form";

function TopNavbar() {
  //const modalRef = useRef();
  const [showLogin, setShowLogin] = useState(false);
  const is_loagged_in = localStorage.getItem("is_loagged_in");
  //const verifiedUserData = localStorage.getItem("verified_user_data");
  //console.log('verifiedUserData here ',verifiedUserData);

  const { loginData, finalData } = useContext(multiStepContext);

  console.log("loginData from TopNavbar.js", loginData);
  console.log("finalData from TopNavbar.js", finalData);

  const [action, setAction] = useState();
  const [authToken, setAuthToken] = useState();

  console.log("action from TopNavbar.js", action);
  console.log("authToken from TopNavbar.js", authToken);

  useEffect(() => {
    if (is_loagged_in === "true" && finalData !== null) {
      setAction(finalData.verified_user_data.action);
      setAuthToken(finalData.verified_user_data.Authorization);
    }
  }, []);


  const renderLoggedInElement = (isForMobile) => {
    if (isForMobile) {
      if (is_loagged_in === "true") {
        return (
          <>
            <ul className="navbar-nav mb-4 ">
              
              <li className="nav-item">
                <NavLink
                  to="/my-courses"
                  className={(navData) =>
                    navData.isActive
                      ? "nav-link w-100 active"
                      : "nav-link w-100"
                  }
                >
                  My Courses
                </NavLink>
              </li>
            </ul>

            <Link className="btn btn-primary rounded-pill w-100" to="/logout">
              Logout
            </Link>
          </>
        );
      } else {
        return (
          <>
            <Button
              className="rounded-pill px-4"
              onClick={() => setShowLogin(true)}
              data-bs-dismiss="offcanvas"
            >
              Sign Up / In
            </Button>
            <Form show={showLogin} close={() => setShowLogin(false)} />
          </>
        );
      }
    } else {
      if (is_loagged_in === "true") {
        return (
          <>
            <NavLink
              to="/my-courses"
              className={(navData) =>
                navData.isActive ? "nav-link active" : "nav-link"
              }
            >
              My Courses
            </NavLink>

            <ul className="nav navbar-nav ms-auto">
              <li className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <img
                    className="rounded-circle"
                    style={{ height: 24, width: 24, objectFit: "cover" }}
                    src={
                      (finalData.verified_user_data.action == 'login')?finalData.verified_user_data.user_data.full_profile_image:'assets/skillbary-theme/static-assets/avatar/default_green_boy.png'
                    }
                    alt="..."
                  />{" "}
                  {(finalData.verified_user_data.action == 'login')?finalData.verified_user_data.user_data.student_name:'New Account'}
                </a>
                <div className="dropdown-menu dropdown-menu-end custom-dropdown-menu">
                  <a href="#" className="dropdown-item">
                    My Certificates
                  </a>
                  <a href="#" className="dropdown-item">
                    Grade Sheet
                  </a>
                  <a href="#" className="dropdown-item">
                    Account Setting
                  </a>
                  <Link to="/logout" className="dropdown-item text-danger">
                    Logout
                  </Link>
                </div>
              </li>
            </ul>
          </>
        );
      } else {
        return (
          <>
            <Button
              className="rounded-pill px-4"
              onClick={() => setShowLogin(true)}
              data-bs-dismiss="offcanvas"
            >
              Sign Up / In
            </Button>
            <Form show={showLogin} close={() => setShowLogin(false)} />
          </>
        );
      }
    }
  };

  return (
    <React.Fragment>
      <section>
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top bg-white shadow-sm py-3">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img
                src="../../assets/skillbary-theme/web-logo.svg"
                alt="Skillbary"
              />
            </Link>

            <button
              className="navbar-toggler p-0 border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
              <ul className="navbar-nav position-absolute top-50 start-50 translate-middle text-light">
                <li className="nav-item me-4">
                  <NavLink
                    to="/"
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item me-4">
                  <NavLink
                    to="/courses"
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                  >
                    Courses
                  </NavLink>
                </li>
                <li className="nav-item me-4">
                  <NavLink
                    to="/careers"
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                  >
                    Careers
                  </NavLink>
                </li>
                <li className="nav-item me-4">
                  <a
                    className="nav-link"
                    href="https://blog.skillbary.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blogs
                  </a>
                </li>
              </ul>

              <div className="ms-auto">
                <ul className="navbar-nav ml-auto fw-bold">
                  {renderLoggedInElement(false)}
                </ul>
              </div>
            </div>
          </div>
        </nav>

        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <Link className="offcanvas-title" id="offcanvasExampleLabel" to="/">
              <img
                className="img-fluid"
                src="../assets/skillbary-theme/web-logo.svg"
                alt="/"
              />
            </Link>

            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <nav
              className="navbar navbar-light top-0 bottom-0 start-0  pt-3 pb-4 px-4 bg-white"
              style={{ overflowY: "auto" }}
            >
              <div className="d-flex flex-column w-100 h-100">
                <div className="d-flex justify-content-between mb-4"></div>
                <div>
                  <ul className="navbar-nav mb-4">
                    <li className="nav-item">
                      <NavLink
                        to="/"
                        className={(navData) =>
                          navData.isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        Home
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        to="/courses"
                        className={(navData) =>
                          navData.isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        Courses
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        to="/careers"
                        className={(navData) =>
                          navData.isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        Careers
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="https://blog.skillbary.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Blogs
                      </a>
                    </li>
                  </ul>
                  <div className="border-top pt-4 mb-5">
                    {renderLoggedInElement(true)}
                  </div>
                </div>
                <div className="mt-auto">
                  <p>
                    <span className="me-2">Get in Touch</span>
                    <a href="/">info@skillbary.com</a>
                  </p>
                  <a className="me-2" href="/">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a className="me-2" href="/">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a className="me-2" href="/instagram">
                    <i className="bi bi-instagram"></i>
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default TopNavbar;
