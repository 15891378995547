import React, { useContext } from "react";
import { multiStepContext } from "../StepContext";
import UserName from "./UserName";

//import UserName from "./UserName";

import UserAvatar from "./UserAvatar";
import UserQualification from "./UserQualification";
import UserReferral from "./UserReferral";

export default function EnrollForm() {
  const {
    currentStep,
    /*setStep,
    loginData,
    setLoginData,
    finalData,
    setFinalData,*/
  } = useContext(multiStepContext);

  const StepDisplay = (formStepNo) => {
    //console.log("formStepNo data", formStepNo);
    //return <h1>Return StepDisplay</h1>;
    console.log("StepDisplay formStepNo", formStepNo);

    if (formStepNo > 6 || formStepNo === 0) {
      formStepNo = 3;
    }
    switch (formStepNo) {
      case 3:
        return <UserName />;

      case 4:
        return <UserAvatar />;

      case 5:
        return <UserQualification />;

      case 6:
        return <UserReferral />;

      default:
        return <UserName />;
    }
  };

  return <>{StepDisplay(currentStep)}</>;
}
