import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { multiStepContext } from "../StepContext";

import {
  apiEndPoint,
  splitGSettingString,
  CryptoJSAesEncrypt,
  CryptoJSAesDecrypt,
} from "../Helpers";

export default function UserAvatar() {
  const { currentStep, setStep, loginData, setLoginData } =
    useContext(multiStepContext);

  const [loadAvatar, setloadAvatar] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadAvatarData = () => {
      const generalSetting = localStorage.getItem("generalSetting");

      if (generalSetting) {
        const [api_user_name, api_token_name] = splitGSettingString(
          generalSetting,
          27
        );

        const token = CryptoJSAesEncrypt(api_user_name, api_token_name);
        console.log("api_token_name token", token);

        const formData = new FormData();

        formData.append("emotions_type", "all");
        formData.append("token", token);
        formData.append("activity_by", "web");

        axios
          .post(apiEndPoint() + "/mobile_app/account/avatars", formData, {})
          .then((res) => {
            console.log("avatars UserAvatar.js res", res.data.data);
            if (res.data.status === 200) {
              console.log(
                "get_general_setting header.js status",
                res.data.status
              );

              const decryptData = CryptoJSAesDecrypt(
                res.data.token,
                res.data.data
              );

              console.log("avatars decryptData", decryptData);

              if (decryptData) {
                const avatarList = JSON.parse(decryptData);
                console.log("ourCourse courseList", avatarList);
                setloadAvatar(avatarList);
                setIsLoading(false);
              }

              //localStorage.setItem("generalSetting", decode(res.data.data));
              //setGetGeneralSetting(decode(res.data.data));
            }
          })
          .catch((error) => {
            console.log("UserAvatar error", error);
          });
      }
    };

    loadAvatarData();
  }, []);

  const handleAvatarSelectionChange = (e) => {
    const target = e.target;
    if (target.checked) {
      setLoginData({ ...loginData, avatar_id: e.target.value });
      //console.log('handleAvatarSelectionChange called', e.target.value)
    }
  };

  return (
    <>
      {loadAvatar ? (
        <section className="py-5 bg-white" style={{ marginTop: "5rem" }}>
          <div className="container">
            <div className="px-4 py-5 my-5">
              <div className="d-flex">
                <div
                  className="backbtn"
                  role="button"
                  onClick={() => setStep(currentStep - 1)}
                >
                  <i className="bi bi-arrow-left fs-4 fw-bold" />
                </div>
                <div className="ms-3">
                  <h5 className="mb-1">Choose Your Avatar</h5>
                  <p className="text-muted">
                    <span>Set your looks</span>
                  </p>
                </div>
              </div>

              <div className="py-5">
                <form>
                  <div className="container text-center">
                    <div className="row g-4">
                      {isLoading ? (
                        <div>Loading ...</div>
                      ) : (
                        loadAvatar.map((avatar) => (
                          <div className="col py-4" key={avatar.id}>
                            <input
                              type="radio"
                              className="btn-check"
                              name="options-avatar"
                              id={`success-outlined${avatar.id}`}
                              autoComplete="off"
                              value={avatar.id}
                              checked={loginData["avatar_id"] === avatar.id}
                              onChange={handleAvatarSelectionChange}
                            />
                            <label
                              className={`rounded-circle avatar-${
                                avatar.color
                              }-hover ${
                                loginData["avatar_id"] === avatar.id ||
                                loginData["avatar_id"] === null
                                  ? "X"
                                  : "grayscaleavatar"
                              }`}
                              htmlFor={`success-outlined${avatar.id}`}
                            >
                              <img
                                className="img-fluid"
                                src={`assets/skillbary-theme/static-assets/avatar/${avatar.image}`}
                                alt="true"
                                width={106}
                              />
                            </label>
                          </div>
                        ))
                      )}
                    </div>
                    <div className="row g-4">
                      <div className="col py-4 text-end">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          <button
                            className="btn btn-primary rounded-pill btn-lg px-4 gap-3"
                            onClick={(e) => setStep(currentStep + 1)}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div>
          <h2>Avatar Loading...</h2>
        </div>
      )}
    </>
  );
}
