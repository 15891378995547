import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
//import { multiStepContext } from "./StepContext";
import Header from "./Header";
import Footer from "./Footer";
import Form from "./Login/Form";

// guest.js
// MobileNumber
// MobileOtp
// UserName.js
// UserAvatar.js
// UserQualification.js
// UserReferral.js

function Login() {
  //const Navigate = useNavigate();

  //const { finalData, setFinalData } = useContext(multiStepContext);
  //const [authenticated, setauthenticated] = useState(null);
  const loggedInUser = localStorage.getItem("is_loagged_in");
  useEffect(() => {
  }, [loggedInUser]);


  if (loggedInUser) {
    return <Navigate replace={true} to="/" />;
  }

  return (
    <>
      <main className="flex-shrink-0">
        <Header />
        <Form />
      </main>
      <Footer />
    </>
  );
}

export default Login;
