function HomeFooter() {
    return (
      <>
        <footer className="bg-transparent mt-auto">
          <section className="py-5 bg-white">
            <div className="container">
              <h3>Our Media Presence</h3>
            </div>
          </section>
          <section className="bg-custom-halfwb">
            <div className="container">
              <div className="row row-cols-1 row-cols-md-4 g-4">
                <div className="col">
                  <div className="card h-100 rounded-4">
                    <div className="card-body">
                      <h5 className="card-title">
                        Some Dummy News Headline Here
                      </h5>
                      <p className="card-text text-gray">23rd November 2022</p>
                      <p className="card-text">
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder text commonly used to demonstrate...
                      </p>
                    </div>
                    <div className="card-footer bg-transparent border-0">
                      <img
                        src="../assets/skillbary-theme/cnbc.svg"
                        className="card-img-top w-25 pb-4"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 rounded-4">
                    <div className="card-body">
                      <h5 className="card-title">
                        Some Dummy News Headline Here
                      </h5>
                      <p className="card-text text-gray">23rd November 2022</p>
                      <p className="card-text">
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder text commonly used to demonstrate...
                      </p>
                    </div>
                    <div className="card-footer bg-transparent border-0">
                      <img
                        src="../assets/skillbary-theme/cnbc.svg"
                        className="card-img-top w-25 pb-4"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 rounded-4">
                    <div className="card-body">
                      <h5 className="card-title">
                        Some Dummy News Headline Here
                      </h5>
                      <p className="card-text text-gray">23rd November 2022</p>
                      <p className="card-text">
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder text commonly used to demonstrate...
                      </p>
                    </div>
                    <div className="card-footer bg-transparent border-0">
                      <img
                        src="../assets/skillbary-theme/cnbc.svg"
                        className="card-img-top w-25 pb-4"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 rounded-4">
                    <div className="card-body">
                      <h5 className="card-title">
                        Some Dummy News Headline Here
                      </h5>
                      <p className="card-text text-gray">23rd November 2022</p>
                      <p className="card-text">
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder text commonly used to demonstrate...
                      </p>
                    </div>
                    <div className="card-footer bg-transparent border-0">
                      <img
                        src="../assets/skillbary-theme/cnbc.svg"
                        className="card-img-top w-25 pb-4"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5 bg-dark">
            <div className="container">
              <div className="row mb-5">
                <div className="col-12 col-lg-4 mb-4 mb-lg-0 text-center text-lg-start">
                  <h6 className="text-white">Our Social Handles</h6>
                  <a className="me-3 text-white" href="/">
                    <i className="bi bi-instagram" />
                  </a>
                  <a className="me-3 text-white" href="/">
                    <i className="bi bi-facebook" />
                  </a>
                  <a className="me-3 text-white" href="/">
                    <i className="bi bi-linkedin" />
                  </a>
                  <a className="me-3 text-white" href="/">
                    <i className="bi bi-whatsapp" />
                  </a>
                  <a className="me-3 text-white" href="/">
                    <i className="bi bi-twitter" />
                  </a>
                  <a className="text-white" href="/">
                    <i className="bi bi-youtube" />
                  </a>
                </div>
                <div className="col-12 col-lg-4 mb-5 mb-lg-0  text-center text-lg-start">
                  <h6 className="text-white">Contact Us</h6>
                  <p>
                    <i className="bi bi-envelope-at-fill text-white me-2" />
                    <span className="text-muted fs-6 mb-0">
                      support@skillbary.com
                    </span>
                  </p>
                  <p>
                    <i className="bi bi-telephone-fill text-white me-2" />
                    <span className="text-muted fs-6 mb-0">1800 020 2022</span>
                  </p>
                </div>
                <div className="col-12 col-lg-4 mb-4 mb-lg-0 text-center text-lg-left">
                  <a className="text-white" href="/">
                    <img
                      src="../assets/skillbary-theme/google-play-badge.svg"
                      alt="Skillbary"
                    />
                  </a>
                </div>
              </div>
              <div className="row">
                <hr className="text-light" />
                <div className="col-12 col-lg-4 text-center text-lg-left order-1 order-lg-0">
                  <p className="small text-muted">
                    Copyright 2023 @ Skillbary Pvt. Ltd. All Rights Reserved
                  </p>
                </div>
                <div className="col-12 col-lg-8 mb-4 mb-lg-0 text-center text-lg-right">
                  <a className="me-3" href="/">
                    Terms &amp; Conditions
                  </a>
                  <a className="me-3" href="/">
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </section>
        </footer>
  
        
      </>
    );
  }
  
  export default HomeFooter;
  