import React from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Main from "./Main";
//import { BrowserRouter as Router, Routes ,Switch, Route, Link } from "react-router-dom"
import { Routes, Route } from "react-router-dom";
import PageNotFound from "./PageNotFound";
import Login from "./Login";
import DashboardIndex from "./Dashboard/DashboardIndex";
import Logout from "./Login/Logout";
import CourseIndex from "./Courses/CourseIndex";
import CourseDetailsPage from "./Courses/CourseDetailsPage";
import CareersIndex from "./Careers/CareersIndex";
import MyCourses from "./Dashboard/MyCourses";
import LearnIndex from "./Learn/LearnIndex";
import Enroll from "./Login/Enroll";

function App() {
  
  return (
    <React.Fragment>
      <Routes>
        <Route index element={<Main />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout/>} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="enroll" element={<Enroll/>} />
        <Route path="dashboard" element={<DashboardIndex/>} />
        <Route path="careers" element={<CareersIndex/>} />
        <Route path="courses" element={<CourseIndex/>} />
        <Route path="my-courses" element={<MyCourses/>} />
        <Route path="courses/:slug" element={<CourseDetailsPage/>} />
        <Route path="learn/:slug" element={<LearnIndex/>} />
      </Routes>
      <ToastContainer  />
    </React.Fragment>
  )


}

export default App;
