import React, { useState, useContext, useEffect } from "react";
import { Navigate,Link,useParams} from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import CoursePlayer from './CoursePlayer';

export default function LearnIndex() {
  const { slug } = useParams();
  const [loadMyCourseDetails, setLoadMyCourseDetails] = useState(null);

  console.log('LearnIndex slug', slug);

  const loggedInUser = localStorage.getItem("is_loagged_in");

  if (!loggedInUser) {
    return <Navigate replace={true} to="/login" />;
  } 

  return (
    <>
      <main className="flex-shrink-0">
        <Header />
        <CoursePlayer/>
      </main>
      <Footer />
    </>
  )
}
