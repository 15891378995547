import React, { useState, useContext, useEffect } from "react";
import { multiStepContext } from "../src/StepContext";
import axios from "axios";
import { decode } from "base-64";
import {
  apiEndPoint,
  splitGSettingString,
  CryptoJSAesEncrypt,
  CryptoJSAesDecrypt,
} from "../src/Helpers";
import TopNavbar from "./TopNavbar";

export function Header() {
  //const { generalSetting, setGetGeneralSetting } = useContext(multiStepContext);

  const generalSetting = localStorage.getItem("generalSetting");

  const loadSettingData = () => {
    if (generalSetting == null) {
      console.log("header.js !generalSetting if", generalSetting);

      const formData = new FormData();

      formData.append("request_from", "7832417389");

      axios
        .post(
          apiEndPoint() + "/mobile_app/account/get_general_setting",
          formData,
          {}
        )
        .then((res) => {
          console.log("get_general_setting header.js res", res.data.data);
          if (res.data.status === 200) {
            console.log(
              "get_general_setting header.js status",
              res.data.status
            );

            localStorage.setItem("generalSetting", decode(res.data.data));
            //setGetGeneralSetting(decode(res.data.data));
          }
        })
        .catch((error) => {
          console.log("get_general_setting error", error);
        });
    }
  };

  useEffect(() => {
    //console.log("header.js useEffect ", generalSetting);
    if (generalSetting == null) {
      loadSettingData();
    }
    //console.log("header.js generalSetting then", generalSetting);
  }, [loadSettingData]);

  return (
    <>
      <TopNavbar />
    </>
  );
}

export default Header;
