import React, { useContext } from "react";
import { multiStepContext } from "../StepContext";

export default function UserReferral() {
  const { currentStep, setStep } = useContext(multiStepContext);

  return (
    <>
      <section className="py-5 bg-white" style={{ marginTop: "5rem" }}>
        <div className="container">
          <div className="px-4 py-5 my-5">
            <div className="d-flex">
            <div
                className="backbtn"
                role="button"
                onClick={() => setStep(currentStep - 1)}
              >
                <i className="bi bi-arrow-left fs-4 fw-bold" />
              </div>
              <div className="ms-3">
                <h5 className="mb-1">Enter Your Referral Code</h5>
                <p className="text-muted">
                  <span>
                    Once entered, you will be eligible for a Referral discount
                    of up tp Rs. 80,000.
                  </span>
                </p>
              </div>
            </div>
            <div >
              <form>
                <div className="hstack hstack-sm gap-3">
                  <input
                    className="form-control-lg me-auto referral-code"
                    maxLength={7}
                    type="text"
                    placeholder="ABCDEFG"
                    aria-label="ABCDEFG"
                  />
                  <button
                    href="user-account-dashboard.html"
                    className="btn btn-link text-muted px-4 gap-3"
                  >
                    Skip for Now
                  </button>
                  
                  <button
                    onClick={() => setStep(currentStep + 1)}
                    className="btn btn-primary rounded-pill btn-lg px-4 gap-3"
                  >
                    Apply Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
