import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { decode } from "base-64";
import { multiStepContext } from "../src/StepContext";
import { Player } from "@lottiefiles/react-lottie-player";
import { apiEndPoint } from "../src/Helpers";
import Home from "./Home";
import HomeFooter from "./HomeFooter";
import Header from "./Header";

function Main() {
  const [loading, setLoading] = useState(false);
  const generalSetting = localStorage.getItem("generalSetting");

  useEffect(() => {
    setLoading(false); //setLoading(true);

    console.log("generalSetting main js", generalSetting);

  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Player
            src="../assets/skillbary-theme/lotties/splash_new_anim.json"
            className="player"
            loop={false}
            autoplay={true}
            speed={1}
            style={{ height: "50%", width: "50%" }}
            onEvent={(event) => {
              if (event === "complete") {
                setLoading(false);
                console.log("lotties complete");
              }
            }}
          />
        </div>
      ) : (
        <>
          <main className="flex-shrink-0">
            <Header />
            <Home />
          </main>
          <HomeFooter />
        </>
      )}
    </>
  );
}

export default Main;
