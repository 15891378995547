import React from "react";
import UniversityPartners from "./Home/UniversityPartners";
import OurFeatures from "./Home/OurFeatures";
import OurCourses from "./Home/OurCourses";
import OurAlumni from "./Home/OurAlumni";
import ExploreCourse from "./Home/ExploreCourse";
import Banner from "./Home/Banner";

function Home() {
  return (
    <>

    
      <div className="home-section">
        <Banner/>
        <ExploreCourse/>
        <OurCourses/>
        <OurFeatures/>
        <UniversityPartners/>
        <OurAlumni/>
      </div>
    </>
  );
}

export default Home;
