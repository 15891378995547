import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { multiStepContext } from "../StepContext";

import UserAccount from "./UserAccount";
import Header from "../Header";
import Footer from "../Footer";

import Form from "../Login/Form";

function DashboardIndex() {
  const { currentStep, setStep } = useContext(multiStepContext);

  const loggedInUser = localStorage.getItem("is_loagged_in");
  const loginAction = localStorage.getItem("loginAction");

  useEffect(() => {
    if (loginAction === "register") {
      setStep(3);
    }
  }, []);

  if (!loggedInUser) {
    return <Navigate replace={true} to="/login" />;
  }



  return (
    <>
      <main className="flex-shrink-0">
        <Header />
        {loginAction === "register" ? <Form /> : <UserAccount />}
      </main>
      <Footer />
    </>
  );

  if (loginAction === "register") {
    console.log("DashboardIndex register called");
    //console.log("DashboardIndex currentStep", currentStep);
    //setStep(currentStep + 1);
    //return <Navigate replace={true} to="/enroll" />;
  }
}

export default DashboardIndex;
