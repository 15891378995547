import React, { useEffect, useContext } from "react";
import { toastUniqueId } from "../Helpers";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { multiStepContext } from "../StepContext";

export default function Logout() {
  const navigate = useNavigate();

  const { setStep, setLoginData, setFinalData } =
    useContext(multiStepContext);


  useEffect(() => {
    localStorage.removeItem("is_loagged_in");
    localStorage.removeItem("is_refferal");
    localStorage.removeItem("verified_user_data");
    localStorage.removeItem("loginAction");
    
    

    toast.success("Logout Successfully!", {
      theme: "dark",
      position: "bottom-center",
      toastId: toastUniqueId(),
      onClose: () => {
        setStep(0);
        setLoginData({
          dial_code: "91",
          mobile: null,
          name: null,
          avatar_id: null,
          qualification_id: null,
          otp: null,
          request_type: "sms",
          device_token: null,
          activity_by: "web",
          version: "0.1",
          is_refferal: false,
          is_otp_verified: false,
        });
        setFinalData({ verified_user_data: null });
        navigate("/");
      },
    });
  });

  return (
    <></>
  );
}
