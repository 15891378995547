import React, { useState, createContext } from "react";
import Form from "./App";

const multiStepContext = createContext();

function StepContext() {
  const [currentStep, setStep] = useState(0);

  const generalSetting = localStorage.getItem("generalSetting");
  //const [generalSetting, setGetGeneralSetting] = useState(0);

  const [loginData, setLoginData] = useState({
    dial_code: "91",
    mobile: null,
    name: null,
    avatar_id: null,
    qualification_id: null,
    otp: null,
    request_type: "sms",
    device_token: null,
    activity_by: "web",
    version: "0.1",
    is_refferal: false,
    is_otp_verified : false,
  });


  const userTempdata = JSON.parse(localStorage.getItem("verified_user_data"));
  const [finalData, setFinalData] = useState(userTempdata);
  
  
  return (
    <div>
      <multiStepContext.Provider
        value={{
          currentStep,
          setStep,
          loginData,
          setLoginData,
          finalData,
          setFinalData,
          generalSetting
        }}
      >
        <Form />
      </multiStepContext.Provider>
    </div>
  );
}

export default StepContext;

export { multiStepContext };
