import React from "react";
import { Link } from "react-router-dom";
function ExploreCourse() {
  return (
    <React.Fragment>
      <section className="bg-light">
        <div className="container">
          <div className="row text-center" style={{textAlign: 'center'}}>
            <div className="col-12 col-lg-4 py-4">
              <span className="text-primary me-2">
                <i className="bi bi-briefcase-fill"></i>
              </span>{" "}
              <span className="fw-bold">Jobs in 1000+</span>{" "}
              <span>Top Companies</span>
            </div>
            <div className="col-12 col-lg-4 py-4">
              <span className="text-primary me-2">
                <i className="bi bi-graph-up-arrow"></i>
              </span>{" "}
              <span className="fw-bold">50%</span> <span>Average Hike</span>
            </div>
            <div className="col-12 col-lg-4 py-4">
              <span className="text-primary me-2">
                <i className="bi bi-book-half"></i>
              </span>{" "}
              <span className="fw-bold">Top 1%</span>{" "}
              <span>Global Universities</span>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-custom-halfgraywhite d-none">
        <div className="container">
          <div className="row">
            <div
              className="col p-2 align-middle text-center"
              style={{textAlign: 'center'}}
            >
              <Link
                className="btn btn-lg btn-primary rounded-pill px-4 fw-bold "
                to="/courses"
              >
                Explore Our Courses
              </Link>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ExploreCourse;
