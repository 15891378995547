import React from "react";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

export default function UniversityPartners() {
  const imgs = [
    "assets/skillbary-theme/static-assets/partners/harvard.svg",
    "assets/skillbary-theme/static-assets/partners/oguog.svg",
    "assets/skillbary-theme/static-assets/partners/iitd.svg",
    "assets/skillbary-theme/static-assets/partners/california-uni.svg",
    "assets/skillbary-theme/static-assets/partners/harvard.svg",
    "assets/skillbary-theme/static-assets/partners/iitd.svg",
    "assets/skillbary-theme/static-assets/partners/oguog.svg",
  ];

  const settings = {
    lazyload: true,
    mouseDrag: true,
    items: 5,
    gutter: 10,
    slideBy: "page",
    nav: false,
    loop: true,
    autoplay: false,
    autoplayButtonOutput: false,
    autoHeight: true,

    responsive: {
      0: {
        items: 1,
      },
      640: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1440: {
        items: 5,
      },
    },
  };

  const clickEvent = (slide) => {
    console.log(slide);
  };

  return (
    <React.Fragment>
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12">
              <h3 className="fw-bold">Our Top University Partners</h3>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="row container m-0 gx-5 text-center ">
                {imgs.length > 0 ? (
                  <>
                    <TinySlider settings={settings} onInit={clickEvent}>
                      {imgs.map((el, index) => (
                        <div
                          key={index}
                          className="col align-middle py-2 testslider"
                        >
                          <img
                            className={"img-fluid p-2 "}
                            src={el}
                            data-src={el}
                            alt={index}
                          />
                        </div>
                      ))}
                    </TinySlider>
                  </>
                ) : (
                  <>
                    <h1>Loading Image Slider</h1>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
