import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
//import { multiStepContext } from "./StepContext";

import EnrollForm from "./EnrollForm";
import Header from "../Header";
import Footer from "../Footer";

// UserName.js
// UserAvatar.js
// UserQualification.js
// UserReferral.js

function Enroll() {
  
  const loggedInUser = localStorage.getItem("is_loagged_in");
  const loginAction = localStorage.getItem("loginAction");
  useEffect(() => {
  }, [loggedInUser]);


  if (loggedInUser && loginAction === 'login') {
    return <Navigate replace={true} to="/dashboard" />;
  }

  return (
    <>
      <main className="flex-shrink-0">
        <Header/>
        <EnrollForm/>
      </main>
      <Footer/>
    </>
  );
}

export default Enroll;
